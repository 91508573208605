import React, { useState, useEffect, useCallback } from 'react';
import Modal from '@mui/material/Modal';
import { postRequest } from '../../../config';

import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import { Typography } from '@mui/material';

const CategoryLogsModal = ({ dispatch, toggleModal, modal, subPanel }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)

    // Using useCallback to memoize the API call function
    const handleFetchData = useCallback(async () => {
        if (modal.open === 'allocationManagementLogs') {
            setLoading(true)
            let data = { endpoint: '/category' }
            if (subPanel !== "Category Management") {
                data = {
                    ...data,
                    endpoint: subPanel !== "Featured Business Card" ? "/api/admin/assign/category/section" : "/userAllocation/section",
                    ...(subPanel !== "Featured Business Card" ? { allocationType: subPanel === "Allocation Section Management" ? "section" : "expension" } : { message: 'User allocated!' })
                }
            }

            try {
                const response = await postRequest('/log', data);
                if (response && response.code === 200) {
                    setData(response.body);
                }
            } catch (error) {
                console.error('Error fetching created categories:', error);
            } finally {
                setLoading(false)
            }
        }
    }, [dispatch, toggleModal, modal.open]);

    // Effect to fetch created categories
    useEffect(() => {
        handleFetchData();
    }, [handleFetchData, modal.open]);


    return (
        <Modal
            open={modal.open === 'allocationManagementLogs'}
            onClose={() => dispatch(toggleModal({ open: '' }))}
            className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic"
        >
            <div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
                <img
                    className="h2rem pa t0 center-horizontally"
                    src={require('../../../../images/logo.svg')}
                    alt="Logo"
                    style={{ top: '15px' }}
                />
                <button
                    className="close-popup"
                    onClick={() => dispatch(toggleModal({ open: '' }))}
                >
                    <svg
                        style={{ position: 'relative', top: '4px' }}
                        className="pen fds h1rem f1 mr0-5rem"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                    >
                        <path
                            className="pen fa-primary"
                            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                        />
                    </svg>
                </button>
                <h1 className="text-gradient fs1-5rem fw600 tac">{subPanel} Logs</h1>
                <h2 className="c4 fs0-75rem ttu w100 tac mb1rem">See the history of {subPanel} Categories</h2>
                <div className="ma oya dg gg0-5rem mb2rem w100">
                    {data.length > 0 && !loading ? (
                        data.map((log, index) => (
                            <div key={index} className="df my0-5rem">
                                {log.userId?.profile ? (
                                    <img
                                        className="ass ml0-5rem br100 w2rem h2rem client-profile"
                                        src={log.userId.profile}
                                        alt={log.userId.name || `${log.userId.firstName} ${log.userId.lastName}`}
                                        data-tooltip={log.userId.name || `${log.userId.firstName} ${log.userId.lastName}`}
                                    />
                                ) : (
                                    <span
                                        className="ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-75rem client-profile"
                                        data-tooltip={log.userId.name || `${log.userId.firstName} ${log.userId.lastName}`}
                                    >
                                        {log.userId.name
                                            ? `${log.userId.name.split(' ')[0][0] || '?'}${log.userId.name.split(' ')[1]?.[0] || '?'}`
                                            : `${log.userId.firstName[0] || '?'}${log.userId.lastName[0] || '?'}`}
                                    </span>
                                )}
                                <div className="w100">
                                    <span className="fs0-75rem ml1rem">
                                        <b>{log.message}</b> at <b>{new Date(log.timestamp).toLocaleString()}</b>
                                    </span>
                                    <div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
                                        {
                                            subPanel === "Category Management" ? <p><b>Category Name:</b> {log?.['payload']?.['name']}</p> : <>
                                                <p><b>Section Name:</b> {log?.['payload']?.['sectionNo']}</p>
                                                {
                                                    log?.['payload']?.['categoryName'] && <p><b>Assigned Category:</b> {log?.['payload']?.['categoryName']}</p>
                                                }
                                                {
                                                    log?.['payload']?.['advisorName'] && <p><b>Advisor Name:</b> {log?.['payload']?.['advisorName']}</p>
                                                }

                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        ))
                    ) : !loading ? (
                        <h2 className="c4 fs1-50rem ttu w100 tac mb1rem">No Logs Found!</h2>
                    ) : (<>
                        <div className='df aic'>
                            <Skeleton variant="circular">
                                <Avatar />
                            </Skeleton>
                            <Skeleton width="100%" sx={{ marginLeft: '12px', height: '70px' }}>
                                <Typography>.</Typography>
                            </Skeleton>
                        </div>
                        <div className='df aic'>
                            <Skeleton variant="circular">
                                <Avatar />
                            </Skeleton>
                            <Skeleton width="100%" sx={{ marginLeft: '12px', height: '70px' }}>
                                <Typography>.</Typography>
                            </Skeleton>
                        </div>
                        <div className='df aic'>
                            <Skeleton variant="circular">
                                <Avatar />
                            </Skeleton>
                            <Skeleton width="100%" sx={{ marginLeft: '12px', height: '70px' }}>
                                <Typography>.</Typography>
                            </Skeleton>
                        </div>
                        <div className='df aic'>
                            <Skeleton variant="circular">
                                <Avatar />
                            </Skeleton>
                            <Skeleton width="100%" sx={{ marginLeft: '12px', height: '70px' }}>
                                <Typography>.</Typography>
                            </Skeleton>
                        </div>
                        <div className='df aic'>
                            <Skeleton variant="circular">
                                <Avatar />
                            </Skeleton>
                            <Skeleton width="100%" sx={{ marginLeft: '12px', height: '70px' }}>
                                <Typography>.</Typography>
                            </Skeleton>
                        </div>
                    </>)}
                </div>
            </div>
        </Modal>
    );
};

export default CategoryLogsModal;
