import React, { useCallback, useEffect } from 'react';
import { postRequest } from '../../../config';

const IndirectCompanyUserAdCard = ({ onCardLog, LiButton, user, company, truncateText, activePoint, allocatedAdvisor, visibleSection, getCompanyById }) => {

    const handleHistoryGenerate = useCallback(async () => {
        const isUserRole = /^user$/i.test(user['role']);
        if ((visibleSection) && isUserRole && company?.advisorData?.find((obj) => !obj?.createdBy)) {
            try {
                await postRequest('/history/generate', {
                    sectionNo: visibleSection.includes('.') ? visibleSection.split('.')[0] : visibleSection,
                    advisorId: company?.advisorData?.find((obj) => !obj?.createdBy)?._id
                });
            } catch (err) {
                console.log(err);
            }
        }
    }, [visibleSection, user, company]); // Dependencies for useCallback

    useEffect(() => {
        handleHistoryGenerate();
    }, [handleHistoryGenerate, visibleSection]); // Trigger on component load and when visibleSection changes

    return (
        <>
            <div className="user-info-sidebar 12 check mt12 br8px cp df py0-5rem px0-5rem aic fdc pa b0 bg5" style={{ width: "94%", paddingLeft: '0.5rem' }}>
                <div className="bs1 bo2 bss bw2px br8px px0-5rem">
                    <div className="df w100 jcsb mt0-5rem">
                        <div className="df fdc">
                            <div className="df">
                                <img className="br100"
                                    style={{ width: '40px', height: '40px' }}
                                    src={allocatedAdvisor?.profile ? allocatedAdvisor?.profile : require('../../../../images/profile-photo.png')}
                                    alt="allocated-Advisor-Image"
                                />
                                {
                                    (allocatedAdvisor && allocatedAdvisor.linkedIn) && <div className="pt0-5rem pb0-5rem df fdc ml0-5rem jcc" style={{ marginBottom: "7px" }}>
                                        <LiButton
                                            link={allocatedAdvisor?.linkedIn}
                                            company={company}
                                            user={user}
                                            allocatedAdvisor={allocatedAdvisor} />
                                    </div>
                                }
                            </div>
                        </div>
                        {company['getLogo'] &&
                            <img className="bg1"
                                style={{ height: "35px", width: '40px', borderRadius: '50%', padding: '2px', backgroundColor: "#c9e4ff" }}
                                src={allocatedAdvisor?.email == 'stephen.clarke@yopmail.com' ? require("../../../../images/advisor-logo.png")
                                    : (allocatedAdvisor?.email == 'nevil.durrant@yopmail.com' || allocatedAdvisor?.email == 'paul.dodd@yopmail.com' || allocatedAdvisor?.email == 'jacinta.magee@yopmail.com') ? require("../../../../images/cfo-center.png")
                                        : company['getLogo']}
                                alt="image"
                                onClick={() => getCompanyById(allocatedAdvisor?.companyId)}
                            />
                        }
                    </div>
                    <div
                        className="mt0-5rem fw500 w100 businessCardText"
                        style={{ textTransform: 'capitalize' }}
                        onClick={() => onCardLog(company, user, allocatedAdvisor, 'name', { visibleSection })}
                    >
                        {`${allocatedAdvisor?.firstName} ${allocatedAdvisor?.lastName ?? ""}`}
                    </div>
                    <div className="fw500 w100 businessCardText"
                        style={{ textTransform: 'capitalize' }}
                        onClick={() => onCardLog(company, user, allocatedAdvisor, 'title', { visibleSection })}>
                        {allocatedAdvisor?.title}
                    </div>
                    <div className="fw500 px0-5rem mt0-5rem mb0-5rem" style={{ paddingLeft: '0px', fontSize: '11px', width: "100%" }}>
                        <div className="fw500" style={{ fontSize: '11px', width: "100%" }}>
                            <p className='businessCardText'>
                                {truncateText(`Need to chat about your ${allocatedAdvisor?.sectionName} requirements?  No obligation call...`, 100)}
                            </p>
                            <p className="mt0-5rem" onClick={() => onCardLog(company, user, allocatedAdvisor, 'phone number', { visibleSection })} >
                                <span className="fw300 businessCardText">Tel:</span>{" "}
                                <a
                                    className='businessCardText'
                                    href={`tel:${allocatedAdvisor?.phone}`}
                                >
                                    {allocatedAdvisor?.phone}
                                </a>
                            </p>
                            <p onClick={() => onCardLog(company, user, allocatedAdvisor, "email", { visibleSection })}>
                                <span className="fw300 businessCardText">Email:</span>{" "}
                                <a
                                    className='businessCardText'
                                    href={`mailto:${allocatedAdvisor?.email}`}
                                >
                                    {truncateText(allocatedAdvisor?.email, 30)}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IndirectCompanyUserAdCard;
