import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { postRequest, showMessage } from '../../../config';
import { Delete } from '@mui/icons-material';

const DeleteCategory = ({ modal, dispatch, toggleModal, getCategorys, selectedData }) => {

    // Hooks and vars
    const [loading, setLoading] = useState(false)


    // Handle Submit
    const handleSubmit = async () => {
        if (loading) return
        setLoading(true)
        try {
            let response = await postRequest(`/admin/delete/category`, {
                id: selectedData?._id,
                name: selectedData?.name
            });
            if (response['code'] == 200) {
                showMessage(response['message']);
                getCategorys()
                dispatch(toggleModal({ open: '' }))
            }
        } catch (err) {
            console.log(err, "error")

        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Modal
                open={modal['open'] == 'deleteModal'}
                onClose={() => dispatch(toggleModal({ open: '' }))}
                className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic"
            >
                <div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
                    <img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
                    <button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
                        <svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                        </svg>
                    </button>

                    <div className="row fdc tac">
                        <Delete sx={{
                            fontSize: '8rem',
                            margin: '20px auto'
                        }} />
                        Are you sure to delete {selectedData?.name} category?
                    </div>

                    <div className="df jcse">
                        <span
                            onClick={() => {
                                if (loading) return
                                dispatch(toggleModal({ open: "" }))
                            }}
                            className="shadow bg4 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem"
                        >
                            Cancel
                        </span>
                        <span
                            onClick={handleSubmit}
                            className="shadow bg3 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem"
                        >
                            Delete
                        </span>
                    </div>
                </div >
            </Modal >
        </>
    )
}

export default DeleteCategory