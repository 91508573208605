import React from "react";
import ScrollToTop from "react-scroll-to-top";

export default function Footer() {

	return <>
		<footer className="w100 zi-1 pf b0 l0 bg5"></footer>
		<ScrollToTop
			smooth
			viewBox="0 0 27 39"
			svgPath="M24.2285 18.1712C24.4081 18.3446 24.5513 18.552 24.6498 18.7814C24.7483 19.0107 24.8002 19.2574 24.8023 19.507C24.8045 19.7566 24.7569 20.0041 24.6624 20.2351C24.5679 20.4662 24.4283 20.6761 24.2518 20.8526C24.0753 21.0291 23.8654 21.1686 23.6344 21.2632C23.4034 21.3577 23.1559 21.4052 22.9063 21.4031C22.6567 21.4009 22.41 21.349 22.1806 21.2505C21.9513 21.152 21.7439 21.0088 21.5705 20.8293L15.3803 14.639V36.4185C15.3803 36.9171 15.1822 37.3952 14.8297 37.7477C14.4771 38.1003 13.999 38.2983 13.5005 38.2983C13.0019 38.2983 12.5238 38.1003 12.1712 37.7477C11.8187 37.3952 11.6206 36.9171 11.6206 36.4185V14.639L5.43044 20.8293C5.0759 21.1717 4.60106 21.3611 4.10818 21.3569C3.6153 21.3526 3.14382 21.1549 2.79529 20.8064C2.44676 20.4578 2.24906 19.9863 2.24477 19.4935C2.24049 19.0006 2.42997 18.5257 2.77239 18.1712L12.1714 8.77216C12.346 8.5971 12.5535 8.45821 12.7819 8.36345C13.0102 8.26868 13.2551 8.2199 13.5023 8.2199C13.7496 8.2199 13.9944 8.26868 14.2228 8.36345C14.4512 8.45821 14.6586 8.5971 14.8332 8.77216L24.2285 18.1712ZM24.7793 0.702148H2.2216C1.72305 0.702148 1.24491 0.900199 0.89238 1.25273C0.539848 1.60526 0.341797 2.0834 0.341797 2.58196C0.341797 3.08051 0.539848 3.55865 0.89238 3.91118C1.24491 4.26371 1.72305 4.46176 2.2216 4.46176H24.7793C25.2779 4.46176 25.756 4.26371 26.1085 3.91118C26.4611 3.55865 26.6591 3.08051 26.6591 2.58196C26.6591 2.0834 26.4611 1.60526 26.1085 1.25273C25.756 0.900199 25.2779 0.702148 24.7793 0.702148Z"
		/>
		{/* <div className="pf b0 r0 mr1rem mb5rem">
			<svg className="w3rem h3rem cp f3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="fa-primary" d="M249.1 268C235.4 268 224 279.4 224 294S235.4 320 249.1 320C264.6 320 276 308.6 276 294S264.6 268 249.1 268zM277.7 96H234.3C201.8 96 176 121.6 176 153.8c0 10.74 9.166 19.83 20 19.83S216 164.6 216 153.8c0-9.914 8.334-18.17 18.33-18.17h43.33c10 0 18.33 8.26 18.33 18.17c0 6.607-3.334 12.39-9.166 15.7L239.3 197.6c-6.668 4.131-10 10.74-10 17.35v13.22c0 10.74 9.166 19.83 20 19.83c10.83 0 20-9.088 20-19.83V226.5l38.33-23.13C325.2 192.7 336 173.7 336 153.8C336 121.6 310.2 96 277.7 96z" /><path className="fa-secondary" d="M448 0H64C28.63 0 0 28.62 0 63.1v287.1C0 387.4 28.63 415.1 64 415.1h96v83.1c0 9.873 11.25 15.52 19.12 9.649L304 415.1H448c35.38 0 64-28.63 64-63.1V63.1C512 28.62 483.4 0 448 0zM249.1 320C235.4 320 224 308.6 224 294S235.4 268 249.1 268C264.6 268 276 279.4 276 294S264.6 320 249.1 320zM307.7 203.4l-38.33 23.13v1.652c0 10.74-9.168 19.83-20 19.83c-10.83 0-20-9.088-20-19.83V214.1c0-6.609 3.332-13.22 10-17.35l47.5-28.09C292.7 166.2 296 160.4 296 153.8c0-9.914-8.334-18.17-18.33-18.17H234.3c-10 0-18.33 8.26-18.33 18.17c0 10.74-9.166 19.83-20 19.83S176 164.6 176 153.8C176 121.6 201.8 96 234.3 96h43.33C310.2 96 336 121.6 336 153.8C336 173.7 325.2 192.7 307.7 203.4z" /></svg>
		</div> */}
	</>
}