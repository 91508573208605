// Custom react select 
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        border: 'none',
        boxShadow: 'none',
        minHeight: 'initial',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.5rem',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
    }),
    placeholder: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    menu: (provided, state) => ({
        ...provided,
        width: "96%",
        marginTop: 0,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#fc7785" : state.isFocused ? "#fde3e6" : provided.backgroundColor,
        color: state.isSelected ? "white" : provided.color,
        ':active': {
            ...provided[':active'],
            backgroundColor: state.isSelected ? "#fc7785" : "#fde3e6",
        },
    }),
};

export { customStyles }