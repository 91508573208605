import moment from 'moment';
import Cropper from 'react-cropper';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import React, { useEffect, useState } from "react";
import 'react-circular-progressbar/dist/styles.css';
import { useSelector, useDispatch } from "react-redux";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText } from "@mui/material";

import 'cropperjs/dist/cropper.css';
import AnimatedPage from "../../../components/AnimatedPage";
import { toggleModal } from "../../../reducers/modalReducer";
import { getRequest, postRequest, showMessage } from '../../../config';

const cropper = React.createRef(null);

const liButtonStyle = {
	borderRadius: "2.5rem",
	padding: "0px 12px",
	color: "#FFFFFF",
	fontSize: "12px",
	fontWeight: "700",
	lineHeight: "1rem",
};
const truncateText = (text, maxLength) => {
	if (!text || text.length <= maxLength) {
		return text;
	}
	return text.substring(0, maxLength) + '...';
};

export default function InvestorSettings({ profileClass, getCompany, handleMemberEdit }) {

	const dispatch = useDispatch();

	let user = useSelector(state => state.user);
	let modal = useSelector(state => state.modal);
	let companies = useSelector(state => state.companies);
	let companyId = location.pathname.split("/").slice(-1)[0];

	let [cvData, setCvData] = useState({});
	let [company, setCompany] = useState({});
	let [director, setDirector] = useState('');
	let [editInput, setEditInput] = useState('');
	let [uploadType, setUploadType] = useState('');
	let [advisorLogo, setAdvisorLogo] = useState();
	let [attachment, setAttachment] = useState({});
	let [promptScore, setPromptScore] = useState(0);
	let [auditLogList, setAuditLogList] = useState([]);
	let [currentImage, setCurrentImage] = useState('');
	let [isImageModal, setIsImageModal] = useState(false);
	let [companyContact, setCompanyContact] = useState({});
	let [filingReadMore, setFilingReadMore] = useState({});
	let [chargeReadMore, setChargeReadMore] = useState({});
	let [selectedStages, setSelectedStages] = useState([]);
	let [controlReadMore, setControlReadMore] = useState({});
	let [selectedSectors, setSelectedSectors] = useState([]);
	let [officersReadMore, setOfficersReadMore] = useState({});
	let [isMainRecipient, setIsMainRecipient] = useState(false);
	let [updateCompanyData, setUpdateCompanyData] = useState({});
	let [isSettingsChanged, setSettingsChanged] = useState(false);
	let [updateSettingsData, setUpdateSettingsData] = useState({});
	let [tabIndex, setTabIndex] = useState('Companies House Core Info');
	let [investorLeadQuestions, setInvestorLeadQuestions] = useState([]);
	let [tabCompanySnapshot, setTabCompanySnapshot] = useState('Filing History');
	let [leadSectionActive, setLeadSectionActive] = useState('Standard Questions');
	let [formData, setFormData] = useState({ linkedIn: '', companyId: '', directorId: '' });
	let [newContact, setNewContact] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		profilePicture: '',
		linkedIn: '',
	});

	let options = {
		empty: ['', 'not known', 'not selected', 'none selected', 'nil'],
		cStages: ["Pre-Revenue", "Post-Revenue Early Stage", "Mature"],
		noOfInvestments: ['<10', '10-20', '20-100', '>100', 'Not known'],
		fundSizes: ['<£5m', '£5m-£100m', '£100m-£500m', '£500m-£1bn', '>£1bn', 'Not known'],
		lastInvestmentDate: ['Within 6 months', 'Within 12 months', 'More than 12 months', 'Not known'],
		profits: ['Nil', '<£100,000', '£100,000-£500,000', '£500,001-£1m', '>£1m ARR', '£1m-£5m', '£5m-£10m', '£10m-£20m', '£20m-£100m', '£100m+', 'Not known'],
		revenues: ['Nil', '<£100,000', '£100,000-£500,000', '£500,001-£1m', '>£1m ARR', '£1m-£5m', '£5m-£10m', '£10m-£20m', '£20m-£100m', '£100m+', 'Not known'],
		sweetSpots: ['<£100,000', '£100,001-£1,000,000', '£1,000,001-£5,000,000', '£5,000,001-£20,000,000', '£20,000,001-£100,000,000', '>£100,000,001', 'Not known'],
		stages: ['Angel', 'Pre-Seed', 'Seed', 'Venture Capital - Series A', 'Venture Capital - Series B+', 'Private Equity', 'Accelerator', 'Angel Group', 'Convertible Note', 'Corporate Centure Capital', 'Co-working Space', 'Crowdfunding', 'Debt', 'Early Stage Venture', 'Entrepreneurship Program', 'Family Investment Office', 'Fund of Funds', 'Government Office', 'Grant', 'Hedge Fund', 'Incubator', 'Initial Coin Offering', 'Investment Bank', 'IPO', 'Late Stage Venture', 'Non-Equity Assistance', 'Pension Funds', 'Pre-IPO', 'Post-IPO', 'Secondary Market', 'Secondary Purchaser', 'Startup Competition', 'Syndicate', 'University Program', 'Venture Debt', 'Other', 'Not Selected'],
		sectors: ['General', 'Accounting', 'Advertising', 'Advice', 'Aerospace', 'Ag Tech', 'Analytics', 'Angel Investment', 'Apps', 'Artificial Intelligence', 'Asset Management', 'Banking', 'Big Data', 'Biotechnology', 'Blockchain', 'Business Development', 'Business Information Systems', 'CleanTech', 'Cloud Infrastructure', 'Commerce & Shopping', 'Commercial Real Estate', 'Community & Lifestyle', 'Consumer', 'Consumer Electronics', 'Consumer Goods', 'Consulting', 'Credit', 'Cryptocurrency', 'Customer Service', 'Cybersecurity', 'Data & Analytics', 'Data Management', 'Database', 'Delivery', 'Developer APIs', 'Developer Platform', 'Document Management', 'E-Commerce', 'Education', 'Energy', 'Enterprise Software', 'Environmental Engineering', 'Events', 'Female Focus', 'Finance', 'Financial Services', 'FinTech', 'Food & Beverage', 'Gaming', 'Genetics', 'Government', 'Gov Tech', 'GreenTech', 'Hardware', 'Healthcare', 'Higher Education', 'Impact Investing', 'Information Technology', 'Infrastructure', 'Intellectual Property', 'Internet', 'Internet Services', 'Insurance', 'Insurtech', 'Incubators', 'Law Enforcement', 'Legal', 'Legal Tech', 'Lending & Investments', 'Life Science', 'Machine Learning', 'Management Consulting', 'Manufacturing', 'Marketplace', 'Media & Entertainment', 'Medical', 'Medical Device', 'Mobile', 'Mobile Apps', 'Oil & Gas', 'Payments', 'Privacy & Security', 'Productivity Tools', 'Professional Services', 'Prop Tech', 'Real Estate', 'Retail', 'Risk Management', 'SaaS', 'Sales & Marketing', 'Science & Engineering', 'Smart Cities', 'Software', 'Stock Exchanges', 'Sustainability', 'Tax Consulting', 'Transportation', 'Venture Capital', 'Video', 'Wealth Management', 'Web3', 'Web Development', 'Web Hosting', 'Wellness', 'Wholesale', 'Other', 'Not Selected'],
	};

	let companyKeys = {
		fiveWords: 'Description', companyDescription: 'Detail Box', vatRegCode: 'VAT Code', ukUTRCode: 'UTR Code', cAddress: 'Address Line', cPostalCode: 'Postal Code',
		cCountry: 'Country', cWebsite: 'Website Url', cLinkedin: 'LinkedIn Url', cFacebook: 'Facebook Url', cInstagram: 'Instgram Url', cStage: 'Company Stage',
		preferredSectors: 'Preferred Sectors', stageFocus: 'Stage Focus', sweetSpot: 'Sweet Spot', fundSize: 'Funds Available', annualRevenues: 'Annual Revenues',
		annualProfits: 'Annual Profits', noOfInvestments: 'No. of Investments', lastInvestmentDate: 'Date of Last Investment', investmentCriteria: 'Investment Criteria',
		isBVCA: 'Is BVCA', isSeedLegals: 'Is SeedLegals', isCrunchBase: 'Is Crunchbase',
		job: 'Contact Job Title', email: 'Contact Email', phone: 'Contact Phone', linkedIn: 'Contact LinkedIn', bio: 'Contact Bio',
	};

	let invalidEmails = ['hello@', 'enquiries@', 'pitch@', 'info@', 'contact@', 'support@', 'team@', 'admin@', 'feedback@', 'partnership@', 'inquiries@', 'office@'];

	useEffect(() => {
		getInvestorLeadQuestions();
	}, []);

	useEffect(() => {
		let isCompanySettings = localStorage.getItem("isInvestorSettings");
		if (isCompanySettings == null) {
			dispatch(toggleModal({ open: 'companySettings' }));
			localStorage.setItem('isInvestorSettings', true);
		}
	}, []);

	useEffect(() => {
		if (user.companyId?._id) {
			setFormData((prevFormData) => ({ ...prevFormData, companyId: user.companyId?._id }));
			fetchInvestorFirmDetails();
			getPromptScore(user.companyId?._id);
		}
	}, [user.companyId?._id]);

	useEffect(() => {
		setFormData((prevFormData) => ({ ...prevFormData, directorId: director }));
	}, [director]);

	useEffect(() => {
		let tempSectors = [];
		if (company['preferredSectors'] && !options['empty'].includes(company['preferredSectors']['sector1']?.toLowerCase()) && !tempSectors.includes(company['preferredSectors']['sector1'])) tempSectors.push(company['preferredSectors']['sector1']);
		if (company['preferredSectors'] && !options['empty'].includes(company['preferredSectors']['sector2']?.toLowerCase()) && !tempSectors.includes(company['preferredSectors']['sector2'])) tempSectors.push(company['preferredSectors']['sector2']);
		if (company['preferredSectors'] && !options['empty'].includes(company['preferredSectors']['sector3']?.toLowerCase()) && !tempSectors.includes(company['preferredSectors']['sector3'])) tempSectors.push(company['preferredSectors']['sector3']);
		if (company['preferredSectors'] && !options['empty'].includes(company['preferredSectors']['sector4']?.toLowerCase()) && !tempSectors.includes(company['preferredSectors']['sector4'])) tempSectors.push(company['preferredSectors']['sector4']);
		if (company['preferredSectors'] && !options['empty'].includes(company['preferredSectors']['sector5']?.toLowerCase()) && !tempSectors.includes(company['preferredSectors']['sector5'])) tempSectors.push(company['preferredSectors']['sector5']);
		setSelectedSectors(tempSectors);

		let tempStages = [];
		if (company['stageFocus'] && !options['empty'].includes(company['stageFocus']['stage1']?.toLowerCase()) && !tempStages.includes(company['stageFocus']['stage1'])) tempStages.push(company['stageFocus']['stage1']);
		if (company['stageFocus'] && !options['empty'].includes(company['stageFocus']['stage2']?.toLowerCase()) && !tempStages.includes(company['stageFocus']['stage2'])) tempStages.push(company['stageFocus']['stage2']);
		if (company['stageFocus'] && !options['empty'].includes(company['stageFocus']['stage3']?.toLowerCase()) && !tempStages.includes(company['stageFocus']['stage3'])) tempStages.push(company['stageFocus']['stage3']);
		setSelectedStages(tempStages);
	}, [company]);

	const fetchInvestorFirmDetails = async () => {
		const response = await getRequest(`/company/getInvestorFirm/${user.companyId._id}`);
		if (response && response?.['code'] == 200 && response['body']) {
			let { contact, ...companyWithoutContact } = response.body;
			setCompany(companyWithoutContact);
			setCompanyContact(contact);
		}
	};

	const LiButton = ({ link, company, user, allocatedAdvisor, withoutLog }) => {
		return <div
			style={{
				...liButtonStyle,
				background: link ? "linear-gradient(120.13deg, #4A67FF -3.15%, #3956F0 8.51%, #798EFF 71.07%, #8496F4 102.88%)" : '#999',
				cursor: link ? "pointer" : 'text'
			}}
			onClick={() => {
				if (link) {
					window.open(link, '_blank')
				}
				if (withoutLog === false) {
					onCardLog(company, user, allocatedAdvisor, "linkedIn")
				}
			}}
		><img src={require('../../../../images/landingpage/linkedin-logo.svg')} alt='Linkedin Logo' style={{ height: '10px' }} />
		</div>
	};

	const getPromptScore = async (investorId) => {
		const response = await getRequest(`/company/getPromptScore/${investorId}`);
		if (response && response['code'] === 200) setPromptScore(response['body']);
	};

	const getInvestorLeadQuestions = async () => {
		const response = await postRequest(`/question/getModuleQuestions`, { type: 'investor-lead' });
		if (response && response['code'] === 200) setInvestorLeadQuestions(response['body']);
	};

	const getAuditLogs = async (endpoint) => {
		const response = await postRequest('/log', { endpoint, companyId: company['_id'] });
		if (response && response['code'] == 200) {
			await setAuditLogList(response['body']);
			dispatch(toggleModal({ open: 'auditLogs' }));
		}
	};

	const getSettingsLogs = async () => {
		const response = await postRequest('/log', { endpoint: `/company/addEditDeleteInvestor`, action: 'edit', companyId: company['_id'] });
		if (response && response['code'] == 200) {
			await setAuditLogList(response['body']);
			dispatch(toggleModal({ open: 'auditLogs' }));
		}
	};

	const btnPrev = async (id) => {
		let box = await document.querySelector(id);
		let width = await box.clientWidth;
		box.scrollLeft = box.scrollLeft - width;
	};

	const btnNext = async (id) => {
		let box = await document.querySelector(id);
		let width = await box.clientWidth;
		box.scrollLeft = box.scrollLeft + width;
	};

	const uploadImage = async () => {
		let attachment = [];
		if (cropper.current && currentImage) {
			let imageToUpload = cropper?.current?.getCroppedCanvas()?.toDataURL()
			let res = await fetch(imageToUpload);
			let blob = await res.blob();
			const coverFile = new File([blob], "profile image", { type: "image/png" });
			attachment.push(coverFile);
			setCurrentImage(imageToUpload);

			if (uploadType == 'advisorLogo') {
				const response = await postRequest(`/company/updateLogo/${company['_id']}`, { attachment });
				if (response || response["code"] == 200) await setAdvisorLogo(response?.['body']?.['logo']);
				setIsImageModal(false);
				showMessage(response.message);
			}
			else {
				const response = await postRequest(`/company/uploadFile`, { attachment }, true);
				if (response || response['code'] == 200) {
					if (uploadType === 'logo') setCompany({ ...company, logo: response?.['body']?.['report']?.['location'] });
					else if (uploadType === 'newContactImage') setNewContact({ ...newContact, profilePicture: response?.['body']?.['report']?.['location'] });

					setSettingsChanged(true);
					setIsImageModal(false);
				}
				showMessage(response.message);
			}
		}
	};

	const handleImage = async (e) => {
		let image = e.target.files[0]
		if (image && image.size > (5 * 1024 * 1024)) return showMessage("Photo Size must be less than 5MB");
		image = await URL.createObjectURL(image);

		setCurrentImage(image);
	};

	const handleValueChange = (event) => {
		const { name, value } = event.target;

		if (name === 'noOfInvestments' || name === 'lastInvestmentDate') {
			setUpdateSettingsData({ ...updateSettingsData, [name]: value });
			setCompany({ ...company, investment: { ...company['investment'], [name]: value } });
		} else if (name === 'sector') {
			if (value.length <= 5) {
				setSelectedSectors(value);
				setUpdateSettingsData({
					...updateSettingsData,
					preferredSectors: `${value[0] ? value[0] : 'Not Selected'}, ${value[1] ? value[1] : 'Not Selected'}, ${value[2] ? value[2] : 'Not Selected'}, ${value[3] ? value[3] : 'Not Selected'}, ${value[4] ? value[4] : 'Not Selected'}`
				});
				setCompany({
					...company, preferredSectors: {
						...company['preferredSectors'],
						sector1: value[0] ? value[0] : 'Not Selected',
						sector2: value[1] ? value[1] : 'Not Selected',
						sector3: value[2] ? value[2] : 'Not Selected',
						sector4: value[3] ? value[3] : 'Not Selected',
						sector5: value[4] ? value[4] : 'Not Selected',
					}
				});
			} else showMessage('Please select up to 5 sectors');
		} else if (name === 'stage') {
			if (value.length <= 3) {
				setSelectedStages(value);
				setUpdateSettingsData({
					...updateSettingsData,
					stageFocus: `${value[0] ? value[0] : 'Not Selected'}, ${value[1] ? value[1] : 'Not Selected'}, ${value[2] ? value[2] : 'Not Selected'}`
				});
				setCompany({
					...company,
					stageFocus: {
						...company['stageFocus'],
						stage1: value[0] ? value[0] : 'Not Selected',
						stage2: value[1] ? value[1] : 'Not Selected',
						stage3: value[2] ? value[2] : 'Not Selected',
					}
				});
			} else showMessage('Please select up to 3 stages');
		} else if (name === 'isBVCA' || name === 'isSeedLegals' || name === 'isCrunchBase') {
			setUpdateSettingsData({ ...updateSettingsData, [name]: !company['source'][name] });
			setCompany({ ...company, source: { ...company['source'], [name]: !company['source'][name] } });
		} else {
			setUpdateSettingsData({ ...updateSettingsData, [name]: value });
			setCompany({ ...company, [name]: value });
		}

		setSettingsChanged(true);
	};

	const handleContactChange = (event) => {
		const { name, value } = event.target;

		setUpdateSettingsData({ ...updateSettingsData, [name]: value });
		setCompanyContact({ ...companyContact, [name]: value });
	};

	const handleNewContactChange = (event) => {
		const { name, value } = event.target;

		if (name === 'isMainRecipient') {
			if (isMainRecipient) setIsMainRecipient(!isMainRecipient);
			else {
				if (newContact['firstName'] == '' || newContact['lastName'] == '' || newContact['email'] == '' || newContact['phone'] == '' || newContact['linkedIn'] == '') showMessage('Please complete all fields. Contact can only be added if all fields are completed.');
				else setIsMainRecipient(!isMainRecipient)
			}
		} else setNewContact({ ...newContact, [name]: value });
	};

	const saveSettings = async () => {
		if (invalidEmails.some(invalidEmail => companyContact['email'].includes(invalidEmail))) {
			showMessage(`Email can not contain "hello@, enquiries@, pitch@, info@, contact@, support@, team@, admin@, feedback@, partnership@, inquiries@, office@ etc."`);
			return;
		}

		let data = { update: updateSettingsData, companyId: company['_id'], action: 'edit', investorId: company['_id'], investorData: company, contactData: companyContact };
		let response = await postRequest(`/company/addEditDeleteInvestor`, data);

		if (response && response['code'] === 200) {
			showMessage('Settings saved successfully');
			setUpdateSettingsData({});
			setSettingsChanged(false);
		}
	};

	const saveAnswer = async (e) => {
		let update = { ...company, [e.target.name]: e.target.value };
		updateCompanyData = { ...updateCompanyData, [e.target.name]: e.target.value };

		await setUpdateCompanyData(updateCompanyData);
		setCompany(update);

		let response = await postRequest(`/company/update/${company['_id']}`, { update: updateCompanyData, companyId: company['_id'] });

		if (response && response['code'] === 200) showMessage(response['message']);
	};

	const addNewContact = async () => {
		if (newContact['firstName'] == '' || newContact['lastName'] == '' || newContact['email'] == '' || newContact['phone'] == '' || newContact['linkedIn'] == '') {
			showMessage('Please complete all fields. Contact can only be added if all fields are completed.');
			return;
		}

		let data = {
			firstName: newContact['firstName'],
			lastName: newContact['lastName'],
			email: newContact['email'],
			phone: newContact['phone'],
			linkedIn: newContact['linkedIn'],
			profilePicture: newContact['profilePicture'],
			title: 'Team Member',
			isMainRecipient,
			action: 'add',
			permissions: {
				D001: true,
				D002: true,
				D003: true,
				D004: true,
				D005: true,
				D006: true,
				D007: true,
				D008: true,
				D009: true,
				D010: true,
				D011: true,
				D012: true,
				D013: true,
				D014: true,
				D015: true,
				D016: true,
				D017: true,
				D018: true,
				D019: true,
				D020: true,
				DEPF005: true,
				DEPF006: true,
				DEPF007: true,
				DEPN004: true,
			},
		};

		const response = await postRequest(`/user/addAdvisor`, data);
		if (response && response["code"] == 200) {
			dispatch(toggleModal({ open: '' }));
			showMessage('Contact added successfully');
			setIsMainRecipient(false);
			setNewContact({
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				profilePicture: '',
				linkedIn: '',
			});
		}
	};

	const handleAnswer = (e, questionType) => {
		const { name, value } = e['target'];

		if (questionType.includes('YN')) setInvestorLeadQuestions(prevQuestions => prevQuestions.map(question => question['_id'] == name ? { ...question, radioOption: value } : question));
		else if (questionType.includes('Multi')) setInvestorLeadQuestions(prevQuestions => prevQuestions.map(question => question['_id'] == name ? { ...question, dropdown: value.join(';') } : question));
		else if (questionType.includes('T')) setInvestorLeadQuestions(prevQuestions => prevQuestions.map(question => question['_id'] == name ? { ...question, comment: value } : question));
		else setInvestorLeadQuestions(prevQuestions => prevQuestions.map(question => question['_id'] == name ? { ...question, dropdown: value } : question));
	};

	const handleCvChange = async (event) => {
		if (event.target.files) {
			const newAttachment = [];
			for (const file of event.target.files) {
				newAttachment.push(file);
			}
			setAttachment(newAttachment);

			const updatedCvData = { ...cvData };
			updatedCvData[director['_id']] = newAttachment[0];
			setCvData(updatedCvData);
		}
	};

	const showUploadPopup = (director) => {
		setDirector(director['_id']);
		const matchingDirector = company?.['directors'].find(d => d['_id'] === director['_id']);
		const linkedInValue = (matchingDirector && matchingDirector['linkedIn'] !== 'undefined') ? matchingDirector['linkedIn'] : null;

		setFormData({ ...formData, linkedIn: linkedInValue });
		setDirector(director)
	};

	const confirmDeleteFiles = async () => {
		let questionId = "64bf7e6bacdfbb8c415c2cb4";
		let index = company?.directors.findIndex(el => el['_id'] == director['_id']);
		const directorId = director['_id'];
		const res = await postRequest('/repository/deleteFile', { file: attachment, questionId, companyId: company['_id'], directorId });
		if (res && res['code'] == 200) {
			Object.keys(company.modules).map(module => {
				if (module === `directors-questionnaire_${director['_id']}`) {
					setCompany(company => {
						const updatedCompany = { ...company };
						const moduleObj = updatedCompany.modules[module];
						if (moduleObj && moduleObj.sections[0]?.subFolders[2]?.questions[0]?.answer?.files) {
							moduleObj.sections[0].subFolders[2].questions[0].answer.files = moduleObj.sections[0].subFolders[2].questions[0].answer.files?.filter(file => {
								return file !== attachment;
							});
						}
						return updatedCompany;
					});
				}
			})

			const updatedCvData = { ...cvData };
			if (updatedCvData.hasOwnProperty(directorId)) {
				delete updatedCvData[directorId];
			}
			setCvData(updatedCvData);
			await fetchInvestorFirmDetails();
			dispatch(toggleModal({ open: '' }));
			showMessage(res.message);
		}
	};

	const closeDirectorPopup = () => {
		dispatch(toggleModal({ open: '' }));
		setFormData({ ...formData, linkedIn: '' });
	};

	const handleSubmit = async () => {
		let data;
		if (attachment) data = { attachment, ...formData };
		else data = { ...formData };
		let res = await postRequest('/question/saveCV', data);
		if (res && res['code'] == 200) {
			const { updatedQuestion } = res['body'];
			const updatedCompanyDirectors = company.directors.map(director => {
				if (director['_id'] == updatedQuestion['directorId']) return { ...director, cv: updatedQuestion['files'][0] };
				return director;
			});
			const updatedCompany = { ...company, directors: updatedCompanyDirectors };
			// setCompany(st => ({ ...st, updatedCompany }));
			setCompany(updatedCompany);
			await fetchInvestorFirmDetails();
			closeDirectorPopup();
			showMessage(res['message']);
		}
	};

	const getSidebarHTML = () => {
		const tabs = user?.['role'] == 'Advisor'
			? ["Companies House Core Info", "Location & Web Site & Socials", "Director's Info", "Advisor Permissions"]
			: ["Companies House Core Info", "Location & Web Site & Socials", "Director's Info", "Investor Permissions", "Fund Information & Investment Criteria", "Lead Qualification Widget", "Preferred Contact For Leads"];
		return (
			<div id="sidebar-company-settings" style={{ width: "calc((100% - 4rem) / 5)" }} className="esg-sidebar nullfalse w100 bsbb dg gg1rem br5px bg1 ps t0 sidebar-report">
				<div className='df jcc aic fdc p0-5rem'>
					<h3 className="c4 ttu lh1 fs1-25rem mt0-5rem tac">{user?.['role']} Settings</h3>
					<h2 className="c4 ttu lh1 fs1rem mt0-5rem tac">Index</h2>
				</div>
				<hr style={{ border: "none", height: "1px", display: "block", width: "100%", marginTop: "18px", backgroundColor: "#002d63" }} />
				<div className='mah100 mya oya df fdc jcsb mt0-5rem'>
					{tabs.map((tab, idx) => {
						return (
							<>{tab == "Delete Company"
								? <button key={idx} onClick={() => dispatch(toggleModal({ open: 'companyDelete' }))} className={`delete-active w100 df c2 td250 c3-hover px1rem py0-5rem cp bg1`}>
									<span className='fs0-75rem fw500 asc pen uss ass tal'>{tab}</span>
								</button>
								: <button key={idx} onClick={() => { setTabIndex(tab); setUpdateCompanyData({}); setUpdateSettingsData({}) }} className={`${tab == tabIndex && 'active '} w100 df c2 td250 c3-hover px1rem py0-5rem cp bg1`}>
									<span className='fs0-75rem fw500 asc pen uss ass tal'>{tab}</span>
								</button>
							}
							</>
						)
					})}
				</div>
				<div className="user-info-sidebar mt12 cp df py1rem px0-5rem aic fdc  bw0px btw2px pa b0 bg5" style={{ width: "94%", paddingLeft: '10px' }}>
					<div className="df w100 jcsb mt0-5rem">
						<div className="df fdc">
							<div className="df">
								<img className="br100"
									style={{ width: '40px', height: '40px' }}
									src={user?.profile ? user?.profile : require('../../../../images/profile-photo.png')}
									alt="alloctaed-Advisor-Image" />
								<div className="pt0-5rem jcc df fdc ml0-5rem" style={{ marginBottom: '7px' }}>
									<LiButton
										link={user?.linkedIn}
										company={company}
										user={user}
									/>
								</div>
							</div>
						</div>
						{user?.['companyId']?.['logo'] &&
							<img className="bg1"
								style={{ height: "35px", width: '40px', borderRadius: '50%', padding: '2px', backgroundColor: "#c9e4ff" }}
								src={user?.['companyId']?.['logo']}
								alt="image"
							// onClick={() => getCompanyById(user?.companyId)}
							/>
						}
					</div>
					<div className="mt0-5rem fw500 w100" style={{ fontSize: '11px', textTransform: 'capitalize' }}>{`${user?.firstName} ${user?.lastName}`}</div>
					<div className="fw500 w100"
						style={{ fontSize: '9px', textTransform: 'capitalize' }}
						onClick={() => onCardLog(company, user, user, 'title')}>
						{user?.title}
					</div>
					<span className="fw500 px0-5rem mt0-5rem" style={{ width: "96%", paddingLeft: '0px', fontSize: '11px' }}>
						<p className="mt0-5rem">
							<span className="fw300">Tel:</span>{" "}
							<a
								href={`tel:${user?.phone}`}
								onClick={() => onCardLog(company, user, user, 'phone number')} >
								{user?.phone}
							</a>
						</p>
						<p>
							<span className="fw300">Email:</span>{" "}
							<a href={`mailto:${user?.email}`}
								onClick={() => onCardLog(company, user, user, "email")}>
								{truncateText(user?.email, 30)}
							</a>
						</p>
					</span>
				</div>
				{/* <div className="mta cp df jcc py1rem px0-5rem aic bss bw0px btw2px bo6">
					<img className="br100 mr0-5rem w3rem h3rem" src="https://this-person-does-not-exist.com/img/avatar-gen11548c471947e6a4777a2f945b3e6d79.jpg" />
					<span className="fw500 fs1-2rem">Contact Lawyer</span>
				</div> */}
			</div>
		)
	};

	//********** Companies House Core Info **********//
	const companiesHouseCore = () => {
		const tabs = ["Filing History", "Officers", "Persons with significant control", "Charges", "Registers"];
		return (
			<div className="sectionWrapper p1rem fs0-75rem mt2rem pr mr2rem ml1rem">
				<div className='df jcsb'>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
					<button onClick={() => getAuditLogs(`/company/update/${company['_id']}`)} className='cp tac c1 bg3 bg4-hover br5px shadow px1rem py0-5rem fw500'>Audit Log</button>
				</div>
				<div className="br5px p2rem dg gg0-5rem my1rem mb1rem br8px shadow bg5">
					<span className="ttc "><b>Company Status:</b> {company['isActive'] ? 'Active' : '-'} </span>
					<span className="ttc "><b>Company Number:</b> {company['ukRegNo'] ? company['ukRegNo'] : "-"} </span>
					<span className="ttc "><b>Company Title:</b> {company['cName'] ? company['cName'] : "-"} </span>
					<span className="ttc "><b>Company Date of Creation:</b> {company['createdAt'] ? moment(company['createdAt']).format('ll') : "-"} </span>
					<span className="ttc "><b>Company Description:</b> {company['description'] ? company['description'] : company['companyDescription'] ? company['companyDescription'] : "-"} </span>
					<span className="ttc "><b>Company Address:</b> {company['cAddress'] ? company['cAddress'] : "-"} </span>
					{company['ukRegNo'] && <div className='df'>
						<Link to={`https://find-and-update.company-information.service.gov.uk/company/${company['ukRegNo']}`} target='_blank' className='w20 cp tac c1 bg3 bg4-hover br5px shadow px2rem py0-5rem fw500 mt1rem mr1rem'>Companies House - Direct</Link>
						<Link to={`https://find-and-update.company-information.service.gov.uk/company/${company['ukRegNo']}/more`} target='_blank' className='w30 cp tac c1 bg3 bg4-hover br5px shadow px2rem py0-5rem fw500 mt1rem'>Companies House - Company Snapshot</Link>
					</div>}
				</div>
				<div className='list-snapshot mb1rem'>
					<h3 className='text-gradient'>Company snapshot</h3>
					<div className='df jcsb aic my1rem mb1rem p1rem br8px shadow bg5'>
						{tabs.map((tab, idx) => {
							return (
								<button key={idx} className={`${tab == tabCompanySnapshot && 'active '} company-snapshot-btn cp`} onClick={() => setTabCompanySnapshot(tab)}>
									<span className='c2 c3-hover fs1rem tac cp'>{tab}</span>
								</button>
							)
						})}
					</div>
					{tabCompanySnapshot == "Filing History"
						? <>{filingHistoryTab()}</>
						: tabCompanySnapshot == "Officers"
							? <>{officersTab()}</>
							: tabCompanySnapshot == "Persons with significant control"
								? <>{personsSignificantTab()}</>
								: tabCompanySnapshot == "Charges"
									? <>{chargesTab()}</>
									: <>{registersTab()}</>
					}
				</div>
				<div className="pr w100 df">
					<div className='df fdc w50'>
						<label className='fs1rem fww fw600'>VAT:</label>
						<input
							name="vatRegCode"
							id="vatRegCode"
							type="text"
							placeholder="Please add VAT reg number"
							className="shadow bg5 w100 br5px p1rem mt0-5rem mr1rem"
							value={company?.['vatRegCode']}
							onBlur={saveAnswer}
							onChange={(event) => setCompany({ ...company, [event['target']['name']]: event['target']['value'] })}
						/>
					</div>
					<div className='df fdc w50'>
						<label className='fs1rem fww fw600'>UTR:</label>
						<input
							name="ukUTRCode"
							id="ukUTRCode"
							type="text"
							placeholder="Please add UTR number"
							className="shadow bg5 w100 br5px p1rem mt0-5rem"
							value={company?.['ukUTRCode']}
							onBlur={saveAnswer}
							onChange={(event) => setCompany({ ...company, [event['target']['name']]: event['target']['value'] })}
						/>
					</div>
				</div>
			</div>
		)
	};

	const filingHistoryTab = () => {
		return (
			<div className='slider-area mt2rem p1rem px1rem br8px shadow bg5'>
				<h3 className='mt1rem mb1rem text-gradient fs1rem tac'>Filing History</h3>
				<div className="slider-items">
					<div id="filing-history" className="slider-container">
						{company['cFiling'] && company['cFiling'].length > 0
							? <>{company['cFiling'].map((history, idx) => {
								return (<>
									<div key={idx} className="item cp p1rem br5px shadow bg1 mb1rem mt1rem mr2rem">
										<div className='avtar-area df jcc'>
											<img className="h2rem" src={require(`../../../../images/filinghistory.png`)} alt="" />
										</div>
										<div className='text-box'>
											<p className='my0-5rem ttc fs0-75rem'><b>Category:</b> {history['category'] ? history['category'] : '-'}</p>
											<p className='my0-5rem ttc fs0-75rem'><b>Description:</b> {history['description'] ? history['description'] : '-'}</p>
											<p className='my0-5rem ttc fs0-75rem'><b>Document:</b> {history['Location'] ? <a href={history['Location']} target='_blank' className='tdu ttu'>View Document</a> : '-'}</p>
										</div>
										<button onClick={() => { setFilingReadMore(history); dispatch(toggleModal({ open: 'filingReadMore' })) }} className='readmore-btn cp mt1rem tac c1 bg3 bg4-hover br5px shadow px0-25rem py0-5rem fw500'>Read More</button>
									</div>
								</>)
							})}</>
							: <div className="w100 tac"><h2 className='my3rem text-gradient fs1rem tac'>Coming Soon!</h2></div>
						}
					</div>
					{company['cFiling'] && company['cFiling'].length > 0 &&
						<div className='btn-area-slider df jcc mt2rem'>
							<button className="pre-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic mr1rem w2rem h2rem br100" onClick={() => btnPrev('#filing-history')}><i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
							<button className="next-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic w2rem h2rem br100" onClick={() => btnNext('#filing-history')}> <i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
						</div>
					}
				</div>
			</div>
		)
	};

	const officersTab = () => {
		return (
			<div className='slider-area mt2rem p1rem br8px shadow bg5'>
				<h3 className='mt1rem mb1rem text-gradient fs1rem tac'>Officers</h3>
				<div className="slider-items">
					<div id="officers-data" className="slider-container">
						{company['cOfficers'] && company['cOfficers'].length > 0
							? <>{company['cOfficers'].map((data, idx) => {
								return (
									<div key={idx} className="item pre cp p1rem br5px shadow bg1 mb1rem mt1rem mr2rem" >
										<div className='avtar-area df jcc' >
											<img className="h2rem" src={require(`../../../../images/officersavatar.png`)} alt="" />
										</div>
										<div className='text-box' >
											<p className='my0-5rem ttc'><b>Name:</b>  {data['name'] ? data['name'] : '-'}</p>
											<p className='my0-5rem ttc'><b>Role:</b> {data['officer_role'] ? data['officer_role'] : '-'}</p>
											<p className='my0-5rem ttc'><b>Appointed On:</b> {data['appointed_on'] ? data['appointed_on'] : '-'}</p>
											{data['appointment'] && <p className='my0-5rem ttc'><b>{data['appointment']['type']} Filing:</b> <a href={data['appointment']['Location']} target='_blank' className='c3'>View Document</a></p>}
											<p className='my0-5rem ttc'><b>Address:</b> {data['address']
												? <>{data['address']['premises'] && data['address']['premises']}
													{data['address']['address_line_1'] && ', ' + data['address']['address_line_1']}
													{data['address']['address_line_2'] && ', ' + data['address']['address_line_2']}
													{data['address']['locality'] && ' - ' + data['address']['locality']}
													{data['address']['region'] && ', ' + data['address']['region']}
													{data['address']['country'] && ', ' + data['address']['country']}
												</>
												: '-'
											}</p>
										</div>
										<button onClick={() => { setOfficersReadMore(data); dispatch(toggleModal({ open: 'officersReadMore' })) }} className='readmore-btn cp mt1rem tac c1 bg3 bg4-hover br5px shadow px0-25rem py0-5rem fw500'>Read More</button>
									</div>
								)
							})}</>
							: <div className="w100 tac"><h2 className='my3rem text-gradient fs1rem tac'>Coming Soon!</h2></div>
						}
					</div>
					{company['cOfficers'] && company['cOfficers'].length > 0 &&
						<div className='btn-area-slider df jcc mt2rem'>
							<button className="pre-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic mr1rem w2rem h2rem br100" onClick={() => btnPrev('#officers-data')}><i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
							<button className="next-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic w2rem h2rem br100" onClick={() => btnNext('#officers-data')}> <i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
						</div>
					}
				</div>
			</div>
		)
	};

	const personsSignificantTab = () => {
		return (
			<div className='slider-area mt2rem p1rem br8px shadow bg5'>
				<h3 className='mt1rem mb1rem text-gradient fs1rem tac'>Persons with Significant Control</h3>
				<div className="slider-items">
					<div id="persons-significant-data" className="slider-container">
						{company['cSignificantControl'] && company['cSignificantControl'].length > 0
							? <>{company['cSignificantControl'].map((data, idx) => {
								return (
									<div key={idx} className="item pre cp p1rem br5px shadow bg1 mb1rem mt1rem mr2rem" >
										<div className='avtar-area df jcc' >
											<img className="h2rem" src={require(`../../../../images/officersavatar.png`)} alt="" />
										</div>
										<div className='text-box' >
											<p className='my0-5rem ttc'><b>Name:</b>  {data['name'] ? data['name'] : '-'}</p>
											<p className='my0-5rem ttc'><b>Controls:</b>
												{data['natures_of_control'] && data['natures_of_control'].length > 0
													? <>{data['natures_of_control'].map((control, idx) => (
														<ul key={idx}><li>{control}</li></ul>
													))}</>
													: '-'}
											</p>
										</div>
										<button onClick={() => { setControlReadMore(data); dispatch(toggleModal({ open: 'controlReadMore' })) }} className='readmore-btn cp mt1rem tac c1 bg3 bg4-hover br5px shadow px0-25rem py0-5rem fw500'>Read More</button>
									</div>
								)
							})
							}
							</>
							: <div className="w100 tac"><h2 className='my3rem text-gradient fs1rem tac'>Coming Soon!</h2></div>
						}
					</div>
					{company['cSignificantControl'] && company['cSignificantControl'].length > 0 &&
						<div className='btn-area-slider df jcc mt2rem'>
							<button className="pre-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic mr1rem w2rem h2rem br100" onClick={() => btnPrev('#persons-significant-data')}><i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
							<button className="next-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic w2rem h2rem br100" onClick={() => btnNext('#persons-significant-data')}> <i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
						</div>
					}
				</div>
			</div>
		)
	};

	const chargesTab = () => {
		return (
			<div className='slider-area mt2rem p1rem br8px shadow bg5'>
				<h3 className='mt1rem mb1rem text-gradient fs1rem tac'>Charges</h3>
				<div className="slider-items">
					<div id="charges-data" className="slider-container">
						{company['cCharges'] && company['cCharges'].length > 0
							? <>{company['cCharges'].map((data, idx) => {
								return (
									<div key={idx} className="item pre cp p1rem br5px shadow bg1 mb1rem mt1rem mr2rem" >
										<div className='avtar-area df jcc' >
											<img className="h2rem" src={require(`../../../../images/officersavatar.png`)} alt="" />
										</div>
										<div className='text-box' >
											<p className='my0-5rem ttc'><b>Persons Entitled:</b> {data['persons_entitled'] ? data['persons_entitled'][0]['name'] : '-'}</p>
											<p className='my0-5rem ttc'><b>Charge Description:</b> {data['classification']['description'] ? data['classification']['description'] : '-'}</p>
											<p className='my0-5rem ttc'><b>Status:</b> {data['status'] ? data['status'] : '-'}</p>
										</div>
										<button onClick={() => { setChargeReadMore(data); dispatch(toggleModal({ open: 'chargeReadMore' })) }} className='readmore-btn cp mt1rem tac c1 bg3 bg4-hover br5px shadow px0-25rem py0-5rem fw500'>Read More</button>
									</div>
								)
							})}</>
							: <div className="w100 tac"><h2 className='my3rem text-gradient fs1rem tac'>Coming Soon!</h2></div>
						}
					</div>
					{company['cCharges'] && company['cCharges'].length > 0 &&
						<div className='btn-area-slider df jcc mt2rem'>
							<button className="pre-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic mr1rem w2rem h2rem br100" onClick={() => btnPrev('#charges-data')}><i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
							<button className="next-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic w2rem h2rem br100" onClick={() => btnNext('#charges-data')}> <i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
						</div>
					}
				</div>
			</div>
		)
	};

	const registersTab = () => {
		return (
			<div className='slider-area mt2rem p1rem br8px shadow bg5'>
				<h3 className='mt1rem mb1rem text-gradient fs1rem tac'>Registers</h3>
				<div className="slider-items">
					<div id="registers-data" className="slider-container">
						{company['cRegisters'] && company['cRegisters'].length > 0
							? <>{company['cRegisters'].map((data, idx) => {
								return (
									<div key={idx} className="item pre cp p1rem br5px shadow bg1 mb1rem mt1rem mr2rem" >
										<div className='avtar-area df jcc' >
											<img className="h2rem" src={require(`../../../../images/officersavatar.png`)} alt="" />
										</div>
										<div className='text-box' >
											<p className='my0-5rem ttc'><b>Name:</b>  DE ROOS, Peter Wouter</p>
											<p className='my0-5rem ttc'><b>Occupation:</b> Occupation:</p>
											<p className='my0-5rem ttc'><b>Address:</b> Lidl House, 14 , Kingston Road - Surbiton</p>
										</div>
										<button onClick={() => dispatch(toggleModal({ open: 'officersData' }))} className='readmore-btn cp mt1rem tac c1 bg3 bg4-hover br5px shadow px0-25rem py0-5rem fw500'>Read More</button>
									</div>
								)
							})}</>
							: <div className="w100 tac"><h2 className='my3rem text-gradient fs1rem tac'>Coming Soon!</h2></div>
						}
					</div>
					{company['cRegisters'] && company['cRegisters'].length > 0 &&
						<div className='btn-area-slider df jcc mt2rem'>
							<button className="pre-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic mr1rem w2rem h2rem br100" onClick={() => btnPrev('#registers-data')}><i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
							<button className="next-btn shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fs0-75rem usn cp ttu bsbb tac mb1rem df jcc aic w2rem h2rem br100" onClick={() => btnNext('#registers-data')}> <i className="icon"><img className="icon-img" src={require('../../../../images/left-angle-arrow-white.png')} alt="" /> </i></button>
						</div>
					}
				</div>
			</div>
		)
	};

	//********** Location & Web Site & Socials **********//
	const locationWebsite = () => {
		return (
			<div className="sectionWrapper p1rem fs0-75rem mt2rem pr mr2rem ml1rem">
				<div className='df jcsb center-logo-web aic'>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
					{user?.['role'] == 'Advisor' &&
						<div className='center-logo-area df jcc fww pr'>
							<span className='bw2px bss br10px p1rem bsbb w7rem h7rem tac df aic jcc pr' style={{ borderColor: '#E3E3E3' }}>
								{(advisorLogo || user?.["companyId"]?.["logo"]) && <img src={advisorLogo || user["companyId"]["logo"]} className="w4rem cp" />}
								<div className="input-file">
									<span className="add-btn bg5" onClick={() => { setIsImageModal(true), setUploadType('advisorLogo'), setCurrentImage('') }}>
										<svg viewBox="0 0 487 487" className="zi3">
											<g>
												<path
													d="M308.1,277.95c0,35.7-28.9,64.6-64.6,64.6s-64.6-28.9-64.6-64.6s28.9-64.6,64.6-64.6S308.1,242.25,308.1,277.95z
												M440.3,116.05c25.8,0,46.7,20.9,46.7,46.7v122.4v103.8c0,27.5-22.3,49.8-49.8,49.8H49.8c-27.5,0-49.8-22.3-49.8-49.8v-103.9
												v-122.3l0,0c0-25.8,20.9-46.7,46.7-46.7h93.4l4.4-18.6c6.7-28.8,32.4-49.2,62-49.2h74.1c29.6,0,55.3,20.4,62,49.2l4.3,18.6H440.3z
												M97.4,183.45c0-12.9-10.5-23.4-23.4-23.4c-13,0-23.5,10.5-23.5,23.4s10.5,23.4,23.4,23.4C86.9,206.95,97.4,196.45,97.4,183.45z
												M358.7,277.95c0-63.6-51.6-115.2-115.2-115.2s-115.2,51.6-115.2,115.2s51.6,115.2,115.2,115.2S358.7,341.55,358.7,277.95z"
												/>
											</g>
										</svg>
									</span>
								</div>
							</span>
						</div>
					}
					<button onClick={() => getAuditLogs(`/company/update/${company['_id']}`)} className='cp tac c1 bg3 bg4-hover br5px shadow px1rem py0-5rem fw500'>Audit Log</button>
				</div>
				<div className="pr my2rem px2rem py2rem br8px shadow bg5">
					<div className='group-inputs'>
						{editInput == 'cAddress'
							? <p className='df tac p0rem'>
								<input onBlur={(event) => { saveAnswer(event), setEditInput(null) }} defaultValue={company?.['cAddress']} name="cAddress" type="text" placeholder="Enter Address Line" className="c2" />
							</p>
							: <p><b>Address Line:</b> {company?.['cAddress'] ? company['cAddress'] : '-'}
								<span className='edit-btn'>
									<button onClick={() => setEditInput('cAddress')} className='edit-pencil'>
										<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
											<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
										</svg>
									</button>
								</span>
							</p>
						}
					</div>
					<div className='df'>
						<div className='group-inputs mr3rem'>
							{editInput == 'cPostalCode'
								? <p className='df tac p0rem'>
									<input onBlur={(event) => { saveAnswer(event), setEditInput(null) }} defaultValue={company['cPostalCode']} name="cPostalCode" type="text" placeholder="Enter Post Code" className="c2" />								</p>
								: <p><b>Postal Code:</b> {company['cPostalCode'] ? company['cPostalCode'] : '-'}
									<span className='edit-btn'>
										<button onClick={() => setEditInput('cPostalCode')} className='edit-pencil'>
											<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
												<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
											</svg>
										</button>
									</span>
								</p>
							}
						</div>
						<div className='group-inputs '>
							{editInput == 'cCountry'
								? <p className='df tac p0rem'>
									<input onBlur={(event) => { saveAnswer(event), setEditInput(null) }} defaultValue={company['cCountry']} name="cCountry" type="text" placeholder="Enter Country" className="c2" />
								</p>
								: <p><b>Country:</b> {company['cCountry'] ? company['cCountry'] : '-'}
									<span className='edit-btn'>
										<button onClick={() => setEditInput('cCountry')} className='edit-pencil'>
											<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
												<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
											</svg>
										</button>
									</span>
								</p>
							}
						</div>
					</div>
					<div className='group-inputs fs0-75rem'>
						{editInput == 'description'
							? <p className='df tac p0rem'>
								<textarea onBlur={(event) => { saveAnswer(event), setEditInput(null) }} defaultValue={company['description'] ? company['description'] : company['companyDescription'] ? company['companyDescription'] : "-"} name="description" style={{ height: '250px' }} className="c2 w100" placeholder="Detail Box...."></textarea>
							</p>
							: <p className="fs0-75rem"><b>Detail Box:</b> {company['description'] ? company['description'] : company['companyDescription'] ? company['companyDescription'] : "-"}
								<span className='edit-btn'>
									<button onClick={() => setEditInput('description')} className='edit-pencil'>
										<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
											<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
										</svg>
									</button>
								</span>
							</p>
						}
					</div>
					<div className='df mt0-5rem links-area-social'>
						<div className='group-inputs link-group mr3rem'>
							{editInput == 'cWebsite'
								? <p className='df tac p0rem'>
									<input onBlur={(event) => { saveAnswer(event), setEditInput(null) }} defaultValue={company['cWebsite']} name="cWebsite" type="url" placeholder="Enter Website URL" className="c2" />
								</p>
								: <div className='edit-text'>
									<p><b>Website Url :</b> {company['cWebsite'] ? <a href={company['cWebsite']} target='_blank'>{company['cWebsite']}</a> : '-'}</p>
									<span className='edit-btn'>
										<button onClick={() => setEditInput('cWebsite')} className='edit-pencil'>
											<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
												<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
											</svg>
										</button>
									</span>
								</div>
							}
						</div>
						<div className='group-inputs link-group mr3rem'>
							{editInput == "cLinkedin"
								? <p className='df tac p0rem'>
									<input onBlur={(event) => { saveAnswer(event), setEditInput(null) }} defaultValue={company['cLinkedin']} name="cLinkedin" type="url" placeholder="Enter LinkedIn URL" className="c2" />
								</p>
								: <div className='edit-text'>
									<p><b>LinkedIn Url :</b> {company['cLinkedin'] ? <a href={company['cLinkedin']} target='_blank'>{company['cLinkedin']}</a> : '-'}</p>
									<span className='edit-btn'>
										<button onClick={() => setEditInput('cLinkedin')} className='edit-pencil'>
											<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
												<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
											</svg>
										</button>
									</span>
								</div>
							}
						</div>
						<div className='group-inputs link-group'>
							{editInput == "cFacebook"
								? <p className='df tac p0rem'>
									<input onBlur={(event) => { saveAnswer(event), setEditInput(null) }} defaultValue={company['cFacebook']} name="cFacebook" type="url" placeholder="Enter Facebook URL" className="c2" />
								</p>
								: <div className='edit-text'>
									<p><b>Facebook Url :</b> {company['cFacebook'] ? <a href={company['cFacebook']} target='_blank'>{company['cFacebook']}</a> : '-'}</p>
									<span className='edit-btn'>
										<button onClick={() => setEditInput('cFacebook')} className='edit-pencil'>
											<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
												<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
											</svg>
										</button>
									</span>
								</div>
							}
						</div>
					</div>
					<div className='group-inputs link-group'>
						{editInput == "cInstagram"
							? <p className='df tac p0rem'>
								<input onBlur={(event) => { saveAnswer(event), setEditInput(null) }} defaultValue={company['cInstagram']} name="cInstagram" type="url" placeholder="Enter Instagram URL" className="c2" />
							</p>
							: <div className='edit-text'>
								<p><b>Instagram Url :</b> {company['cInstagram'] ? <a href={company['cInstagram']} target='_blank'>{company['cInstagram']}</a> : '-'}</p>
								<span className='edit-btn'>
									<button onClick={() => setEditInput('cInstagram')} className='edit-pencil'>
										<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
											<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
										</svg>
									</button>
								</span>
							</div>
						}
					</div>
				</div>
			</div>
		)
	};

	//********** Director's Info **********//
	const directorsInfo = () => {
		return (
			<div className="sectionWrapper p1rem fs1rem mt2rem pr mr2rem ml1rem">
				<div className='df jcsb'>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
					<button onClick={() => getAuditLogs('/question/saveCV')} className='cp tac c1 bg3 bg4-hover br5px shadow px1rem py0-5rem fw500'>Audit Log</button>
				</div>
				<div className="pr mt2rem df fdr fww">
					{company['directors'] && company['directors'].length > 0
						? <>{company['directors'].map((director, idx) => {
							return (
								<div key={idx} className='directors-info-box df fdc jcc w40 p1rem br8px shadow bg5 mb1rem mt1rem mr2rem'>
									<div className='text-box'>
										<div className='group-inputs df jcc fs1rem'>
											{director['headShot'] && <img src={director['headShot']['location']} className="w4rem h4rem df jcc aic br100 bg3 c1 mr0-5rem mb1rem" />}
										</div>
										<div className='group-inputs fs1rem'>
											<p><b>Name:</b> {director['name'] ? director['name'] : '-'}</p>
										</div>
										<div className='group-inputs fs1rem'>
											<p><b>Role:</b> {director['role'] ? director['role'] : '-'}</p>
										</div>
										<div className='group-inputs fs1rem'>
											<p className='fs1rem'><b>Designation:</b> {director['designation'] ? director['designation'] : '-'}</p>
										</div>
										<div className='group-inputs fs1rem'>
											{/* {Object.keys(company.modules).map(module => {
												if (module === `directors-questionnaire_${director['_id']}`) {
													return company?.modules[module]?.sections[0]?.subFolders[2]?.questions[0]?.answer?.files?.map(file => (
														<><span className="df"><b>CV:</b>
															<div className='df jcc br25px lh1 bg3 c1 px0-5rem ml0-5rem'>
																<a target="_blank" href={file?.location}><span>{file?.originalname}</span></a>
																<svg onClick={(e) => { setAttachment(file); setDirector(director); dispatch(toggleModal({ open: 'deleteFiles' })) }} className="p0-5rem f9 w1rem h1rem cp" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
																	<path class="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
																</svg>
															</div></span>
															<p class="fs0-75rem">Stored at (DEPF007.2 - OCCUPATIONAL)</p>
														</>
													))
												}
											})} */}
											{director['cv'] && <><span className="df"><b>CV:</b>
												<div className='df jcc br25px lh1 bg3 c1 px0-5rem ml0-5rem'>
													<a target="_blank" href={director['cv']['location']}><span>{director['cv']['originalname']}</span></a>
													<svg onClick={(e) => { setAttachment(director['cv']); setDirector(director); dispatch(toggleModal({ open: 'deleteFiles' })) }} className="p0-5rem f9 w1rem h1rem cp" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
														<path class="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
													</svg>
												</div></span>
												<p class="fs0-75rem">Stored at (DEPF007.2 - OCCUPATIONAL)</p>
											</>
											}
										</div>
										<div className='group-inputs fs1rem'>
											{director['linkedIn'] && director['linkedIn'] !== 'undefined' &&
												<p className='df fw500'><b>LinkedIn:</b><a className="fw400 ml0-5rem fs1rem tdu" target="_blank" href={director['linkedIn']}>{director['linkedIn']}</a></p>
											}
										</div>
									</div>
									<button onClick={() => { showUploadPopup(director); dispatch(toggleModal({ open: 'uploadCvData' })) }} className='bsbb jcc w60 df aic shadow fw500 py0-5rem px1rem c1 bg3 br5px mr1rem-xl mt1rem cp'>
										<i className='icon'><img className="" src={require(`../../../../images/upload-icon.png`)} alt="" /></i>Upload
									</button>
								</div>
							)
						})
						}</>
						: <h2 className="w100 mt2rem tac fs1-25rem">This Company Has No Director!</h2>
					}
				</div>
			</div >
		)
	};

	//********** Investor Permissions **********//
	const investorPermissions = () => {
		return (
			<div className="sectionWrapper p1rem fs1rem mt2rem pr mr2rem ml1rem">
				<h3 className="company-name fs1-75rem">Company Name</h3>
				<div className="pr mt2rem">
					<h3>Stage of development:</h3>
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.1 <b>Accountants</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="Accountants" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="Accountants1" id="Accountants" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.2 <b>Lawyers</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="Lawyers" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="Lawyers1" id="Lawyers" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.3 <b>Intellectual Property Advisors</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="IntellectualPropertyAdvisors" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="IntellectualPropertyAdvisors1" id="IntellectualPropertyAdvisors" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.4 <b>Property Advisors</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="PropertyAdvisors" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="PropertyAdvisors1" id="PropertyAdvisors" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.5 <b>PR & Media Advisors</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="PR&MediaAdvisors" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="PR&MediaAdvisors1" id="PR&MediaAdvisors" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.6 <b>Corporate Finance Advisors</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="CorporateFinanceAdvisors" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="CorporateFinanceAdvisors1" id="CorporateFinanceAdvisors" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.7 <b>Commercial Bankers</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="CommercialBankers" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="CommercialBankers1" id="CommercialBankers" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.8 <b>Investment Bankers</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="InvestmentBankers" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="InvestmentBankers1" id="InvestmentBankers" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.9 <b>Insurance Brokers</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="InsuranceBrokers" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="InsuranceBrokers1" id="InsuranceBrokers" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.10 <b>GDPR Advisors</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="GDPRAdvisors" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="GDPRAdvisors1" id="GDPRAdvisors" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.11 <b>Registrars</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="Registrars" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="Registrars1" id="Registrars" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />
					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.12 <b>Corporate Brokers</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="CorporateBrokers" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="CorporateBrokers1" id="CorporateBrokers" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
					<hr style={{ border: "none", height: "1px", display: "block", width: "70%", marginTop: "18px", backgroundColor: "#002d6340" }} />

					<div className='development-customer df aic jcsb mt2rem w70'>
						<div className='left-area mr2rem '>
							<p>D017.13 <b>Other</b></p>
							<div className="pr df mt1rem">
								<input type="url" placeholder="Web site url"
									className="shadow bg5 w100 br5px p1rem mr1rem" />
								<input type="email" placeholder="Email of contact"
									className="shadow bg5 w100 br5px p1rem" />
							</div>
						</div>
						<label for="Other" className="py0-5rem df cp mb0-5rem label-checkbox">
							<span className="checkbox w2rem h2rem pr">
								<input name="Other1" id="Other" className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
							</span>
						</label>
					</div>
				</div>
			</div>
		)
	};

	//********** Fund Information & Investment Criteria **********//
	const ourFundsPreferredInvestmentCriteria = () => {
		const buttonStyle = !isSettingsChanged ? { backgroundColor: 'grey', cursor: 'not-allowed' } : {};
		return (
			<div className="sectionWrapper p1rem fs0-75rem mt2rem pr mr2rem ml1rem">
				<div className='df jcsb'>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
					<button onClick={() => getSettingsLogs()} className='cp tac c1 bg3 bg4-hover br5px shadow px2rem py0-5rem fw500'>Audit Log</button>
				</div>
				<div className="my1rem mb1rem invest-box-funds">
					<div className='form-group df jsc'>
						<div className='img-upload-area pr'>
							<div className='img-box pr'>
								<img src={company?.['logo'] || require('../../../../images/profile-photo.jpeg')} alt="Profile" style={{ width: '100px', height: 'auto', borderRadius: '50px' }} className={`cp c1 shadow p0-5rem ${user['role'] == 'Investor' ? 'investor-bg' : 'advisor-bg'}`} />
								<div className='input-file' style={{ position: 'relative', left: '-5%', bottom: '-50%' }}>
									<span className='add-btn bg5' onClick={() => { setIsImageModal(true), setUploadType('logo'), setCurrentImage('') }}>
										<svg viewBox="0 0 487 487" className="zi3">
											<g>
												<path d="M308.1,277.95c0,35.7-28.9,64.6-64.6,64.6s-64.6-28.9-64.6-64.6s28.9-64.6,64.6-64.6S308.1,242.25,308.1,277.95z
                                                        M440.3,116.05c25.8,0,46.7,20.9,46.7,46.7v122.4v103.8c0,27.5-22.3,49.8-49.8,49.8H49.8c-27.5,0-49.8-22.3-49.8-49.8v-103.9
                                                        v-122.3l0,0c0-25.8,20.9-46.7,46.7-46.7h93.4l4.4-18.6c6.7-28.8,32.4-49.2,62-49.2h74.1c29.6,0,55.3,20.4,62,49.2l4.3,18.6H440.3z
                                                        M97.4,183.45c0-12.9-10.5-23.4-23.4-23.4c-13,0-23.5,10.5-23.5,23.4s10.5,23.4,23.4,23.4C86.9,206.95,97.4,196.45,97.4,183.45z
                                                        M358.7,277.95c0-63.6-51.6-115.2-115.2-115.2s-115.2,51.6-115.2,115.2s51.6,115.2,115.2,115.2S358.7,341.55,358.7,277.95z"/>
											</g>
										</svg>
									</span>
								</div>
								<lable className="ml2rem">Upload/Change investor company logo here</lable>
							</div>
						</div>
					</div>
					<div className='dropdowns-funds mb1rem'>
						<div className='funds-box'>
							<h3 className='mb1rem'>Our Funds</h3>
							<div className='df aic jcsb w100'>
								<div className='item-box mr1rem'>
									<FormControl variant="filled" className='w100'>
										<label>Funds Available For Investment</label>
										<Select
											className="report-dropdown"
											id="demo-simple-select-filled"
											labelId="demo-simple-select-funds-label"
											name='fundSize'
											displayEmpty
											inputProps={{ 'aria-label': 'Without label' }}
											value={company?.['fundSize'] || 'Not known'}
											onChange={(event) => handleValueChange(event)}
										>
											{options.fundSizes.map(fund => <MenuItem value={fund}>{fund}</MenuItem>)}
										</Select>
									</FormControl>
								</div>
								<div className='item-box mr1rem'>
									<FormControl variant="filled" className='w100'>
										<label>Number Of Investments Made</label>
										<Select
											className="report-dropdown"
											id="demo-simple-select-filled"
											labelId="demo-simple-select-funds-label"
											name='noOfInvestments'
											displayEmpty
											inputProps={{ 'aria-label': 'Without label' }}
											value={company?.['investment']?.['noOfInvestments'] || 'Not known'}
											onChange={(event) => handleValueChange(event)}
										>
											{options.noOfInvestments.map(investmentNumber => (
												<MenuItem key={investmentNumber} value={investmentNumber}>
													{investmentNumber}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
								<div className='item-box mr1rem'>
									<FormControl variant="filled" className='w100'>
										<label>Date Of Last Investment </label>
										<Select
											className="report-dropdown"
											id="demo-simple-select-filled"
											labelId="demo-simple-select-funds-label"
											name='lastInvestmentDate'
											displayEmpty
											inputProps={{ 'aria-label': 'Without label' }}
											value={company?.['investment']?.['lastInvestmentDate'] || 'Not known'}
											onChange={(event) => handleValueChange(event)}
										>
											{options.lastInvestmentDate.map(investmentDate => (
												<MenuItem key={investmentDate} value={investmentDate}>
													{investmentDate}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
							</div>
						</div>
						<div className='funds-box mt1rem df jcsb w100 mt1rem'>
							<div className='funds-item w33'>
								<h3 className='mb1rem'>Preferred Sectors?</h3>
								<div className='df aic jcsb w100'>
									<div className='item-box mr1rem'>
										<FormControl variant="filled" className='w100'>
											<label>Preferred Sectors Investment Catagories</label>
											<Select
												multiple
												className="w100 bg5 w100 br5px multiselect-invest"
												placeholder="Sectors"
												name="sector"
												id="preferred-sectors"
												labelId="preferred-sectors-label"
												onChange={(event) => handleValueChange(event)}
												renderValue={(selected) => selected.join(', ')}
												value={selectedSectors || []}
											>
												{options.sectors.map(sector => (
													<MenuItem value={sector} className="multi-dropdown-list">
														<Checkbox checked={selectedSectors.includes(sector)} />
														<ListItemText primary={sector} />
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<h4 className='mt0-5rem'>General, Ag Tech, SaaS, Insurtech</h4>
									</div>
								</div>
							</div>
							<div className='funds-item w33'>
								<h3 className='mb1rem'>Preferred Stage?</h3>
								<div className='df aic jcsb w100'>
									<div className='item-box mr1rem'>
										<FormControl variant="filled" className='w100'>
											<label>Preferred Investment Stage</label>
											<Select
												multiple
												className="w100 bg5 w100 br5px multiselect-invest"
												placeholder="Stage"
												name="stage"
												id="stage-focus"
												labelId="stage-focus-label"
												onChange={(event) => handleValueChange(event)}
												renderValue={(selected) => selected.join(', ')}
												value={selectedStages || []}
											>
												{options.stages.map(stage => (
													<MenuItem value={stage} className="multi-dropdown-list">
														<Checkbox checked={selectedStages.includes(stage)} />
														<ListItemText primary={stage} />
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<h4 className='mt0-5rem'>Seed, Series A, Series B+,</h4>
									</div>
								</div>
							</div>
							<div className='funds-item w33'>
								<h3 className='mb1rem'>Investment Sweet Spot?</h3>
								<div className='df aic jcsb w100'>
									<div className='item-box mr1rem'>
										<FormControl variant="filled" className='w100'>
											<label>Sweet Spot?</label>
											<Select
												className="report-dropdown"
												id="demo-simple-select-filled"
												labelId="demo-simple-select-funds-label"
												name='sweetSpot'
												displayEmpty
												inputProps={{ 'aria-label': 'Without label' }}
												value={company?.['sweetSpot']}
												onChange={(event) => handleValueChange(event)}
											>
												{options.sweetSpots.map(spot => <MenuItem value={spot}>{spot}</MenuItem>)}
											</Select>
										</FormControl>
									</div>
								</div>
							</div>
						</div>
						<div className='funds-box mt2rem'>
							<h3 className='mb1rem'>Preferred Investment Criteria</h3>
							<div className='df aic jcsb w100'>
								<div className='item-box mr1rem'>
									<FormControl variant="filled" className='w100'>
										<label>Company Stage</label>
										<Select
											className="report-dropdown"
											id="demo-simple-select-filled"
											labelId="demo-simple-select-funds-label"
											name="cStage"
											displayEmpty
											inputProps={{ 'aria-label': 'Without label' }}
											value={company?.['cStage']}
											onChange={(event) => handleValueChange(event)}
										>
											{options.cStages.map(stage => <MenuItem value={stage}>{stage}</MenuItem>)}
										</Select>
									</FormControl>
								</div>
								<div className='item-box mr1rem'>
									<FormControl variant="filled" className='w100'>
										<label>Minimum Annual Revenues Current Year</label>
										<Select
											className="report-dropdown"
											id="demo-simple-select-filled"
											labelId="demo-simple-select-funds-label"
											name='annualRevenues'
											displayEmpty
											inputProps={{ 'aria-label': 'Without label' }}
											value={company?.['annualRevenues']}
											onChange={(event) => handleValueChange(event)}
										>
											{options.revenues.map(revenue => <MenuItem value={revenue}>{revenue}</MenuItem>)}
										</Select>
									</FormControl>
								</div>
								<div className='item-box mr1rem'>
									<FormControl variant="filled" className='w100'>
										<label>Minimum Annual Profit Current Year</label>
										<Select
											className="report-dropdown"
											id="demo-simple-select-filled"
											labelId="demo-simple-select-funds-label"
											name='annualProfits'
											displayEmpty
											inputProps={{ 'aria-label': 'Without label' }}
											value={company?.['annualProfits']}
											onChange={(event) => handleValueChange(event)}
										>
											{options.profits.map(profit => <MenuItem value={profit}>{profit}</MenuItem>)}
										</Select>
									</FormControl>
								</div>
							</div>
							<div className="dg gg0-5rem my1rem mb1rem invest-box-funds background-invest mt1rem">
								<h3>Investment Criteria</h3>
								<div className='group-inputs' style={{ minHeight: '10vh' }}>
									{editInput === 'investmentCriteria'
										? (
											<p className='df tac p0rem'>
												<textarea
													rows={10}
													className="c2"
													name="investmentCriteria"
													placeholder="Enter Investment Criteria"
													defaultValue={company?.['investmentCriteria']}
													onBlur={(event) => { handleValueChange(event), setEditInput(null) }}
													onChange={(event) => handleValueChange(event)}
												/>
											</p>
										) : (
											<p>
												{company['investmentCriteria'] || 'Enter the Investment Criteria here...'}
												<span className='edit-btn'>
													<button onClick={() => setEditInput('investmentCriteria')} className='edit-pencil'>
														<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
															<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417,.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
														</svg>
													</button>
												</span>
											</p>
										)}
								</div>
							</div>
							<div className='logos-area df aic jcsb mt2rem mb1em'>
								<div className='logo-brand tac w100'>
									<div className='switch-box'>
										<img className="h3rem pr db mla mra" src={require(`../../../../images/bvca-logo.png`)} alt="TODO" />
										<Switch
											name='isBVCA'
											checked={company?.['source']?.['isBVCA']}
											className="success w100"
											onChange={(event) => handleValueChange(event)}
										/>
									</div>
								</div>

								<div className='logo-brand tac w100'>
									<div className='switch-box'>
										<img className="h3rem pr db mla mra" src={require(`../../../../images/seedlegals.png`)} alt="TODO" />
										<lable className="w100 db">Top UK 45 VCs List</lable>
										<Switch
											name='isSeedLegals'
											checked={company['source']['isSeedLegals']}
											className="success w100"
											onChange={(event) => handleValueChange(event)}
										/>
									</div>
								</div>
								<div className='logo-brand tac w100'>
									<div className='switch-box'>
										<a className="db" href='https://www.crunchbase.com/organization/balderton-capital' target='_blank'>
											<img className="h3rem pr db mla mra" src={require(`../../../../images/crunchbase-logo.png`)} alt="TODO" />
											<Switch
												name='isCrunchBase'
												checked={company['source']['isCrunchBase']}
												className="success w100"
												onChange={(event) => handleValueChange(event)}
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="df jcc">
						<button
							className='cp tac c1 bg3 bg4-hover br5px shadow px2rem py0-5rem fw500 w30'
							onClick={saveSettings}
							style={buttonStyle}
							disabled={!isSettingsChanged}>
							Save Settings
						</button>
					</div>
				</div>
			</div>
		)
	};

	//********** Our Funds Preferred Investment Criteria **********//
	const leadQualificationWidget = () => {
		return (
			<div className="sectionWrapper p1rem fs0-75rem mt2rem pr mr2rem ml1rem">
				<div className='df jcsb'>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
				</div>
				<div className="br5px dg gg0-5rem my1rem mb1rem invest-box-funds mt2rem">
					<div className='w100 df fdc aic'>
						<button onClick={() => setLeadSectionActive('Standard Questions')} style={{ padding: '7px', width: '50%' }}
							className={`${leadSectionActive == 'Standard Questions' && 'active shadow '} df c6 jcc ttc td250 c3-hover cp new-report-btn reports-btn-area`}>
							<span className="pen usn ass mr0-5rem tac fs1rem">Standard Questions <i><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreIcon"><path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg></i></span>
						</button>
						<button onClick={() => setLeadSectionActive('Bespoked Questions')} style={{ padding: '7px', width: '50%' }}
							className={`${leadSectionActive == 'Bespoked Questions' && 'active shadow '} df c6 jcc ttc td250 c3-hover cp new-report-btn reports-btn-area`}>
							<span className="pen usn ass mr0-5rem tac fs1rem">Bespoked Questions <i><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreIcon"><path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg></i></span>
						</button>
					</div>
					{/********* Standard Questions **********/}
					{leadSectionActive == 'Standard Questions' && <>
						<h3 className='tac w100 text-gradient fs1-75rem lh2 mt1rem'>Standard Questions</h3>
						<div class="sectionWrapper bss bw2px bo5 br10px px1rem py0-5rem fs1rem pr">
							<div class="dg">
								{investorLeadQuestions && investorLeadQuestions.map(question => (
									<div data-module-type="two-minute-test-growth" data-section-no="D002.1" data-question-id="645dfb96e0741858c3dc1283" class="bss bw2px bo5 br10px px2rem py1rem mb1rem qustion-box">
										<div class="df fww pr w100 bsbb aic">
											<span className="tooltip-area-custom cp" style={{ top: '-24px', right: '-43px' }}>
												<i className="icon">
													<img className="h2rem" src={require(`../../../../images/info-icon.svg`)} alt="" />
													<div className="overlay-box" style={{ left: '-380px', top: '0', right: 'auto' }}>
														<p className="fs0-75rem c6">Please toggle to include/exclude questions from your applicant lead qualification checklist. Please remember that the longer/more difficult the checklist, the less likely applicant companies are to complete it!</p>
													</div>
												</i>
											</span>
											<div className='left-qustion w70 pr2rem bsbb'>
												<div className='mb2rem'>
													<p class="fs1rem"><b>{question['qNo']} </b>{question['qDescription']}</p>
													{question['questionType'].includes('YN') &&
														<div className="df aic fww">
															{['Yes', 'No', 'N/A'].map((option, idx) =>
																<label key={idx} for={question['_id'] + option} className="py1rem df cp fs0-75rem">
																	<span className="checkbox w30px h30px pr">
																		<input
																			id={question['_id'] + option}
																			name={question['_id']}
																			type="radio"
																			className="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px"
																			data-option={option}
																			defaultChecked={question['radioOption'] == option}
																			onChange={(e) => handleAnswer(e, question['questionType'])}
																		/>
																	</span>
																	<span className="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">{option}</span>
																</label>)
															}
														</div>
													}
													{question['questionType'].includes('DropDown') &&
														<div className="oyh td250 pr mt1rem">
															<select
																name={question['_id']}
																className="pl1rem bss bw2px bo5 w90 br5px p1rem"
																value={question['dropdown'] || ''}
																onChange={(e) => handleAnswer(e, question['questionType'])}
															>
																{question['dropdown'] && <option value={question['dropdown']} selected disabled hidden>{question['dropdown']}</option>}
																{question['options'] && question['options'].map((option, idx) => <option key={idx} value={option['name'].replaceAll("'", ',')}>{option['name'].replaceAll("'", ',')}</option>)}
															</select>
														</div>
													}
													{question['questionType'].includes('Radio') &&
														<div className="df aic fww">
															{question['options'].map((option, idx) =>
																<label key={idx} for={question['_id'] + option['name']} className="py1rem df cp fs0-75rem">
																	<span className="checkbox w30px h30px pr">
																		<input
																			id={question['_id'] + option['name']}
																			name={question['_id']}
																			type="radio"
																			className="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px"
																			data-option={option['name']}
																			defaultChecked={question['dropdown'] == option['name']}
																			onChange={(e) => handleAnswer(e, question['questionType'])}
																		/>
																	</span>
																	<span className="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">{option['name']}</span>
																</label>)
															}
														</div>
													}
													{question?.questionType.includes('Multi') &&
														<div class="multi-dropdown mt1rem">
															<Select
																id="demo-multiple-checkbox"
																name={question['_id']}
																labelId="demo-multiple-checkbox-label"
																multiple
																className="w400px"
																placeholder="Select any options from dropdown"
																onChange={(e) => handleAnswer(e, question['questionType'])}
																renderValue={(selected) => selected.join(', ')}
																value={question['dropdown'] ? question['dropdown'].split(";") : []}>
																{question['options'].map((option, idx) => (
																	<MenuItem value={option['name']} className="multi-dropdown-list">
																		<Checkbox checked={question['dropdown']?.split(";").indexOf(option['name']) > -1} />
																		<ListItemText primary={option['name']} />
																	</MenuItem>
																))}
															</Select>
														</div>
													}
													{question['questionType'] == 'T' &&
														<div className="oyh td250 pr mt1rem">
															<textarea
																rows="3"
																name={question['_id']}
																type="text"
																style={{ resize: "vertical" }}
																className="pl1-5rem bss bw2px bo5 w100 br5px p1rem"
																placeholder='Please enter your answer here'
																defaultValue={question['comment']}
																onBlur={(e) => handleAnswer(e, question['questionType'])}
															></textarea>
														</div>
													}
												</div>
											</div>
											<div className='rightanswer-text w30 pl2rem bsbb'>
												<div class="df jce">
													<div>
														<div class="df gg1rem fs0-75rem">
															<div className='switch-box'>
																<Switch className="success" defaultChecked={true} />
															</div>
														</div>
													</div>

												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</>
					}
					{/********** Bespoked Questions **********/}
					{leadSectionActive == 'Bespoked Questions' && <>
						<h3 className='tac w100 text-gradient fs1-75rem lh2 mt1rem'>Bespoked Questions</h3>
						<div class="bss bw2px bo5 br10px px2rem py1rem mb1rem qustion-box">
							<div class="df fww pr w100 bsbb aic">
								<span className="tooltip-area-custom cp" style={{ top: '-24px', right: '-43px' }}>
									<i className="icon">
										<img className="h2rem" src={require(`../../../../images/info-icon.svg`)} alt="" />
										<div className="overlay-box" style={{ left: '-380px', top: '0', right: 'auto' }}>
											<p className="fs0-75rem c6">Please toggle to include/exclude questions from your applicant lead qualification checklist. Please remember that the longer/more difficult the checklist, the less likely applicant companies are to complete it!</p>
										</div>
									</i>
								</span>
								<div className='left-qustion w70 pr2rem bsbb'>
									<div className='mb2rem'>
										<p class="fs1rem"><b>Q1:</b> Insert bespoked question 1</p>
										<div className='select-box'>
											<input name="firstName" type="text" placeholder="Answer" className="shadow bg5 w100 br5px p1rem mt0-5rem" />
										</div>
										<div class="df aic fww ">
											<div>
												<label for="645dfb96e0741858c3dc1283Yes" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="Yes" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283Yes" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" checked="" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Checkbox</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283No" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="No" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283No" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Multiline</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283N/A" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="N/A" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283N/A" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Radio</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283N/A" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="N/A" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283N/A" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Drowpdowns</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283N/A" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="N/A" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283N/A" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Text</span>
												</label>
											</div>
										</div>
									</div>
									<div>
										<p class="fs1rem"><b>A1:</b> Insert desired answer from company…</p>

										<div className='select-box'>
											<input name="firstName" type="text" placeholder="Answer" className="shadow bg5 w100 br5px p1rem mt0-5rem" />
											{/* <Select
													className="report-dropdown"
													id="demo-simple-select-filled"
													labelId="demo-simple-select-funds-label"
													displayEmpty
													inputProps={{ 'aria-label': 'Without label' }}
													onChange={(e) => handleChange(e, company?.['_id'])}
												>
													<MenuItem value="as-required"><em>Insert bespoked question 1</em></MenuItem>
													<MenuItem value="as-required">Insert bespoked question 1</MenuItem>
													<MenuItem value="5m">Insert bespoked question 1</MenuItem>
													<MenuItem value="100m">Insert bespoked question 1</MenuItem>
													<MenuItem value="500">Insert bespoked question 1</MenuItem>
													<MenuItem value="1000">Insert bespoked question 1</MenuItem>
													<MenuItem value="1bn">Insert bespoked question 1</MenuItem>
													<MenuItem value="notknown">Insert bespoked question 1</MenuItem>
												</Select> */}
										</div>

									</div>
								</div>
								<div className='rightanswer-text w30 pl2rem bsbb'>
									<div class="df jce">
										<div>
											<div class="df gg1rem fs0-75rem">
												<div className='switch-box'>
													<Switch className="success" />
												</div>
											</div>
										</div>
									</div>
									{/* <div className='answer-text'>
											<div className='select-box'>
												<p class="fs1rem">Answer Text</p>
												<input name="QustionText" type="text" placeholder="Text" value="Answer Text" className="shadow bg5 w100 br5px p1rem mt0-5rem" />
											</div>
										</div> */}
								</div>
							</div>
						</div>
						<div class="bss bw2px bo5 br10px px2rem py1rem mb1rem qustion-box">
							<div class="df fww pr w100 bsbb aic">
								<span className="tooltip-area-custom cp" style={{ top: '-24px', right: '-43px' }}>
									<i className="icon">
										<img className="h2rem" src={require(`../../../../images/info-icon.svg`)} alt="" />
										<div className="overlay-box" style={{ left: '-380px', top: '0', right: 'auto' }}>
											<p className="fs0-75rem c6">Please toggle to include/exclude questions from your applicant lead qualification checklist. Please remember that the longer/more difficult the checklist, the less likely applicant companies are to complete it!</p>
										</div>
									</i>
								</span>
								<div className='left-qustion w70 pr2rem bsbb'>
									<div className='mb2rem'>
										<p class="fs1rem"><b>Q2:</b> Insert bespoked question 2</p>
										<div className='select-box'>
											<input name="firstName" type="text" placeholder="Answer" className="shadow bg5 w100 br5px p1rem mt0-5rem" />
										</div>
										<div class="df aic fww ">
											<div>
												<label for="645dfb96e0741858c3dc1283Yes" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="Yes" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283Yes" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" checked="" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Checkbox</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283No" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="No" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283No" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Multiline</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283N/A" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="N/A" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283N/A" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Radio</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283N/A" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="N/A" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283N/A" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Drowpdowns</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283N/A" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="N/A" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283N/A" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Text</span>
												</label>
											</div>
										</div>
									</div>
									<div>
										<p class="fs1rem"><b>A2:</b> Insert desired answer from company…</p>
										<div className='select-box'>
											<input name="firstName" type="text" placeholder="Answer" className="shadow bg5 w100 br5px p1rem mt0-5rem" />
											{/* <Select
													className="report-dropdown"
													id="demo-simple-select-filled"
													labelId="demo-simple-select-funds-label"
													displayEmpty
													inputProps={{ 'aria-label': 'Without label' }}
													onChange={(e) => handleChange(e, company?.['_id'])}
												>
													<MenuItem value="as-required"><em>Insert bespoked question 1</em></MenuItem>
													<MenuItem value="as-required">Insert bespoked question 1</MenuItem>
													<MenuItem value="5m">Insert bespoked question 1</MenuItem>
													<MenuItem value="100m">Insert bespoked question 1</MenuItem>
													<MenuItem value="500">Insert bespoked question 1</MenuItem>
													<MenuItem value="1000">Insert bespoked question 1</MenuItem>
													<MenuItem value="1bn">Insert bespoked question 1</MenuItem>
													<MenuItem value="notknown">Insert bespoked question 1</MenuItem>
												</Select> */}
										</div>

									</div>
								</div>
								<div className='rightanswer-text w30 pl2rem bsbb'>
									<div class="df jce">
										<div>
											<div class="df gg1rem fs0-75rem">
												<div className='switch-box'>
													<Switch className="success" />
												</div>
											</div>
										</div>
									</div>
									{/* <div className='answer-text'>
											<div className='select-box'>
												<p class="fs1rem">Answer Text</p>
												<input name="QustionText" type="text" placeholder="Text" value="Answer Text" className="shadow bg5 w100 br5px p1rem mt0-5rem" />
											</div>
										</div> */}
								</div>
							</div>
						</div>
						<div class="bss bw2px bo5 br10px px2rem py1rem mb1rem qustion-box">
							<div class="df fww pr w100 bsbb aic">
								<span className="tooltip-area-custom cp" style={{ top: '-24px', right: '-43px' }}>
									<i className="icon">
										<img className="h2rem" src={require(`../../../../images/info-icon.svg`)} alt="" />
										<div className="overlay-box" style={{ left: '-380px', top: '0', right: 'auto' }}>
											<p className="fs0-75rem c6">Please toggle to include/exclude questions from your applicant lead qualification checklist. Please remember that the longer/more difficult the checklist, the less likely applicant companies are to complete it!</p>
										</div>
									</i>
								</span>
								<div className='left-qustion w70 pr2rem bsbb'>
									<div className='mb2rem'>
										<p class="fs1rem"><b>Q3:</b> Insert bespoked question 3</p>
										<div className='select-box'>
											<input name="firstName" type="text" placeholder="Answer" className="shadow bg5 w100 br5px p1rem mt0-5rem" />
										</div>
										<div class="df aic fww ">
											<div>
												<label for="645dfb96e0741858c3dc1283Yes" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="Yes" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283Yes" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" checked="" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Checkbox</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283No" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="No" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283No" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Multiline</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283N/A" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="N/A" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283N/A" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Radio</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283N/A" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="N/A" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283N/A" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Drowpdowns</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283N/A" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="N/A" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283N/A" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Text</span>
												</label>
											</div>
										</div>
									</div>
									<div>
										<p class="fs1rem"><b>A3:</b> Insert desired answer from company…</p>

										<div className='select-box'>
											<input name="firstName" type="text" placeholder="Answer" className="shadow bg5 w100 br5px p1rem mt0-5rem" />
											{/* <Select
													className="report-dropdown"
													id="demo-simple-select-filled"
													labelId="demo-simple-select-funds-label"
													displayEmpty
													inputProps={{ 'aria-label': 'Without label' }}
													onChange={(e) => handleChange(e, company?.['_id'])}
												>
													<MenuItem value="as-required"><em>Insert bespoked question 1</em></MenuItem>
													<MenuItem value="as-required">Insert bespoked question 1</MenuItem>
													<MenuItem value="5m">Insert bespoked question 1</MenuItem>
													<MenuItem value="100m">Insert bespoked question 1</MenuItem>
													<MenuItem value="500">Insert bespoked question 1</MenuItem>
													<MenuItem value="1000">Insert bespoked question 1</MenuItem>
													<MenuItem value="1bn">Insert bespoked question 1</MenuItem>
													<MenuItem value="notknown">Insert bespoked question 1</MenuItem>
												</Select> */}
										</div>

									</div>
								</div>
								<div className='rightanswer-text w30 pl2rem bsbb'>
									<div class="df jce">
										<div>
											<div class="df gg1rem fs0-75rem">
												<div className='switch-box'>
													<Switch className="success" />
												</div>
											</div>
										</div>
									</div>
									{/* <div className='answer-text'>
											<div className='select-box'>
												<p class="fs1rem">Answer Text</p>
												<input name="QustionText" type="text" placeholder="Text" value="Answer Text" className="shadow bg5 w100 br5px p1rem mt0-5rem" />
											</div>
										</div> */}
								</div>
							</div>
						</div>
						<div class="bss bw2px bo5 br10px px2rem py1rem mb1rem qustion-box">
							<div class="df fww pr w100 bsbb aic">
								<span className="tooltip-area-custom cp" style={{ top: '-24px', right: '-43px' }}>
									<i className="icon">
										<img className="h2rem" src={require(`../../../../images/info-icon.svg`)} alt="" />
										<div className="overlay-box" style={{ left: '-380px', top: '0', right: 'auto' }}>
											<p className="fs0-75rem c6">Please toggle to include/exclude questions from your applicant lead qualification checklist. Please remember that the longer/more difficult the checklist, the less likely applicant companies are to complete it!</p>
										</div>
									</i>
								</span>
								<div className='left-qustion w70 pr2rem bsbb'>
									<div className='mb2rem'>
										<p class="fs1rem"><b>Q4:</b> Insert bespoked question 4</p>
										<div className='select-box'>
											<input name="firstName" type="text" placeholder="Answer" className="shadow bg5 w100 br5px p1rem mt0-5rem" />
										</div>
										<div class="df aic fww ">
											<div>
												<label for="645dfb96e0741858c3dc1283Yes" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="Yes" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283Yes" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" checked="" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Checkbox</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283No" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="No" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283No" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Multiline</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283N/A" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="N/A" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283N/A" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Radio</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283N/A" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="N/A" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283N/A" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Drowpdowns</span>
												</label>
											</div>
											<div>
												<label for="645dfb96e0741858c3dc1283N/A" class="df cp fs0-75rem py1rem">
													<span class="checkbox w30px h30px pr">
														<input data-option="N/A" name="645dfb96e0741858c3dc1283" id="645dfb96e0741858c3dc1283N/A" class="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px" type="radio" />
													</span>
													<span class="w100 fs0-75rem lh3 ml0-5rem usn mr2rem fw500">Text</span>
												</label>
											</div>
										</div>
									</div>
									<div>
										<p class="fs1rem"><b>A4:</b> Insert desired answer from company…</p>
										<div className='select-box'>
											<input name="firstName" type="text" placeholder="Answer" className="shadow bg5 w100 br5px p1rem mt0-5rem" />
											{/* <Select
													className="report-dropdown"
													id="demo-simple-select-filled"
													labelId="demo-simple-select-funds-label"
													displayEmpty
													inputProps={{ 'aria-label': 'Without label' }}
													onChange={(e) => handleChange(e, company?.['_id'])}
												>
													<MenuItem value="as-required"><em>Insert bespoked question 1</em></MenuItem>
													<MenuItem value="as-required">Insert bespoked question 1</MenuItem>
													<MenuItem value="5m">Insert bespoked question 1</MenuItem>
													<MenuItem value="100m">Insert bespoked question 1</MenuItem>
													<MenuItem value="500">Insert bespoked question 1</MenuItem>
													<MenuItem value="1000">Insert bespoked question 1</MenuItem>
													<MenuItem value="1bn">Insert bespoked question 1</MenuItem>
													<MenuItem value="notknown">Insert bespoked question 1</MenuItem>
												</Select> */}
										</div>

									</div>
								</div>
								<div className='rightanswer-text w30 pl2rem bsbb'>
									<div class="df jce">
										<div>
											<div class="df gg1rem fs0-75rem">
												<div className='switch-box'>
													<Switch className="success" />
												</div>
											</div>
										</div>
									</div>
									{/* <div className='answer-text'>
											<div className='select-box'>
												<p class="fs1rem">Answer Text</p>
												<input name="QustionText" type="text" placeholder="Text" value="Answer Text" className="shadow bg5 w100 br5px p1rem mt0-5rem" />
											</div>
										</div> */}
								</div>
							</div>
						</div>
					</>}
				</div>
			</div>
		)
	};

	//********** Preferred Contact for Leads **********//
	const preferredContactForLeads = () => {
		return (
			<div className="sectionWrapper p1rem fs0-75rem mt2rem pr mr2rem ml1rem">
				<div className='df jcsb'>
					<h3 className="company-name fs1-25rem">{company['cName']}</h3>
					<button onClick={() => getSettingsLogs()} className='cp tac c1 bg3 bg4-hover br5px shadow px2rem py0-5rem fw500'>Audit Log</button>
				</div>
				<div className="br5px dg gg0-5rem my1rem mb1rem invest-box-funds mt2rem">
					<div className='df jcsb aic mt1rem mb1rem'>
						<div className='user-info'>
							<div className='img-box'>
								<img className="w6rem h6rem pr br100" src={companyContact?.['profilePicture'] || require(`../../../../images/profile-photo.jpeg`)} alt="TODO" />
							</div>
							<div className='text-box'>
								<h4 className='df fs1-25rem'>
									<p>{companyContact?.['firstName'] ? companyContact?.['firstName'] : ''} {companyContact?.['lastName'] ? companyContact?.['lastName'] : ''}</p>
									{companyContact?.['linkedIn'] && (
										<a href={companyContact?.['linkedIn'].includes('https') ? companyContact?.['linkedIn'] : `https://${companyContact?.['linkedIn']}`} target='_blank' className="icon ml0-5rem bg1 cp" style={{ width: '15px' }}>
											<i id="icon"><svg version="1.1" className="f3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310">
												<g>
													<path d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
													C77.16,101.969,74.922,99.73,72.16,99.73z"/>
													<path d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
													c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"/>
													<path d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
													c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
													c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
													C310,145.43,300.549,94.761,230.454,94.761z"/>
												</g>
											</svg></i>
										</a>
									)}
								</h4>
								<div className='group-inputs'>
									{editInput == 'job' ? (
										<p className='df tac p0rem fs1rem c4 fw600'>
											<input
												name="job"
												type="text"
												className="c2"
												defaultValue={companyContact?.['job'] || companyContact?.['title']}
												placeholder="Enter job title"
												onBlur={() => { saveSettings(), setEditInput(null) }}
												onChange={(event) => handleContactChange(event)}
											/>
										</p>
									) : (
										<p className='fs1rem c4 fw600'>
											{companyContact?.['job'] ? companyContact?.['job'] : companyContact?.['title'] ? companyContact?.['title'] : ''}
											<span className='edit-btn'>
												<button onClick={() => setEditInput('job')} className='edit-pencil'>
													<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
														<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
													</svg>
												</button>
											</span>
										</p>
									)}
								</div>
							</div>
						</div>
						<div className='progress-area progress-dashboard' style={{ display: 'flex', position: 'relative', justifyContent: 'center' }}>
							<div className='progress-box'>
								<div className="progress-bar" style={{ width: 50, height: 50 }}>
									<CircularProgressbar
										className={`${promptScore > 67 ? 'green' : promptScore > 33 ? 'orange' : 'red'}`}
										value={promptScore || 0}
										text={`${promptScore || 0}%`}
										styles={buildStyles({
											strokeLinecap: 'round',
											strokeWidth: '5px',
											textSize: '20px',
											pathTransitionDuration: 0.5,
											trailColor: '#d6d6d6',
											backgroundColor: '#fc7785',
											dominantBaseline: 'ideographic',
										})}
									/>
								</div>
								<span style={{ marginBottom: '0', fontSize: '11px' }} className="text-center-area">Prompt Response Score</span>
							</div>
						</div>
						<div className='content-area'>
							<ul className="right-list">
								<li>
									Email:
									<span>
										<div className='group-inputs'>
											{editInput == 'email' ? (
												<p className='df tac p0rem'>
													<input
														name="email"
														type="text"
														className="c2"
														defaultValue={companyContact?.['email']}
														placeholder="Enter email"
														onBlur={() => { saveSettings(), setEditInput(null) }}
														onChange={(event) => handleContactChange(event)}
													/>
												</p>
											) : (
												<p>
													{companyContact?.['email']}
													<i className='edit-btn'>
														<button onClick={() => setEditInput('email')} className='edit-pencil'>
															<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
																<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
															</svg>
														</button>
													</i>
												</p>
											)}
										</div>
									</span>
								</li>
								<li>
									Tel:
									<span>
										<div className='group-inputs'>
											{editInput == 'phone' ? (
												<p className='df tac p0rem'>
													<input
														name="phone"
														type="text"
														className="c2"
														defaultValue={companyContact?.['phone']}
														placeholder="Enter telephone number"
														onBlur={() => { saveSettings(), setEditInput(null) }}
														onChange={(event) => handleContactChange(event)}
													/>
												</p>
											) : (
												<p>
													{companyContact?.['phone']}
													<span className='edit-btn'>
														<button onClick={() => setEditInput('phone')} className='edit-pencil'>
															<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
																<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
															</svg>
														</button>
													</span>
												</p>
											)}
										</div>
									</span>
								</li>
								<li>
									LinkedIn:
									<span>
										<div className='group-inputs'>
											{editInput == 'linkedIn' ? (
												<p className='df tac p0rem'>
													<input
														name="linkedIn"
														type="text"
														className="c2"
														defaultValue={companyContact?.['linkedIn']}
														placeholder="Enter linkedIn Profile"
														onBlur={() => { saveSettings(), setEditInput(null) }}
														onChange={(event) => handleContactChange(event)}
													/>
												</p>
											) : (
												<p>
													{companyContact?.['linkedIn'] && (
														<a className='link-area' href={companyContact?.['linkedIn'].includes('https') ? companyContact?.['linkedIn'] : `https://${companyContact?.['linkedIn']}`} target='_blank'>
															{companyContact?.['linkedIn']}
														</a>
													)}
													<span className='edit-btn'>
														<button onClick={() => setEditInput('linkedIn')} className='edit-pencil'>
															<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
																<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
															</svg>
														</button>
													</span>
												</p>
											)}
										</div>
									</span>
								</li>
							</ul>
						</div>
					</div>
					<h3>Investor Bio</h3>
					<div className='group-inputs'>
						{editInput == 'bio' ? (
							<p className='df tac p0rem'>
								<textarea
									rows={5}
									name="bio"
									type="text"
									className="c2"
									defaultValue={companyContact?.['bio']}
									placeholder="Enter bio"
									onBlur={() => { saveSettings(), setEditInput(null) }}
									onChange={(event) => handleContactChange(event)}
								/>
							</p>
						) : (
							<p>
								{companyContact?.['bio'] || 'Enter bio here...'}
								<span className='edit-btn'>
									<button onClick={() => setEditInput('bio')} className='edit-pencil'>
										<svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
											<path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
										</svg>
									</button>
								</span>
							</p>
						)}
					</div>
					<div className='bottomlist-area df fww bss bw2px bo5 br10px p1rem mt1rem'>
						<div className='right-slide-box w100'>
							<div className='df fww tac jcc aic mt1rem mb1rem'>
								<div className='btn-area w30 mr1rem'>
									<button className='pr shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem w100 usn cp ttu bsbb tac df jcc aic' onClick={() => dispatch(toggleModal({ open: 'addContact' }))}>
										Change Contact
										<span className="tooltip-area-custom cp" style={{ top: '-8px', right: '-8px' }}>
											<i className="icon">
												<img className="h2rem" src={require(`../../../../images/info-icon.svg`)} alt="" />
												<div className="overlay-box" style={{ left: 'auto', top: '0', right: '-395px' }}>
													<p className="fs0-75rem c6">We want to provide a great experience to both investors and our applicant companies. We recognise that you may not be the correct contact but ask that you complete all the fields for any individual named  as a replacement. We ask that the  contact email not be enquiries@vcname.com etc.  – we want our curated leads to go to  a named individual. For assessment<br /><br /> Thank you,<br /> Diligentsia Team</p>
												</div>
											</i>
										</span>
									</button>
								</div>
								<div className='btn-area w30'>
									<button className='pr shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem w100 usn cp ttu bsbb tac df jcc aic'>
										Take the VC Product Tour
										<span className="tooltip-area-custom cp" style={{ top: '-8px', right: '-8px' }}>
											<i className="icon">
												<img className="h2rem" src={require(`../../../../images/info-icon.svg`)} alt="" />
												<div className="overlay-box" style={{ left: 'auto', top: '0', right: '-369px' }}>
													<p className="fs0-75rem c6">Learn how Diligentsia can deliver you:<br /><b>Streamlined Deal Process,</b><br /><b>More Certain Outcomes,</b><br /><b>Higher Exit Valuations.</b><br /><b>Improved Portfolio Returns</b></p>
												</div>
											</i>
										</span>
									</button>
								</div>
								<div className='btn-area w30 ml1rem'>
									<button className='pr shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem w100 usn cp ttu bsbb tac df jcc aic' onClick={() => window.open('https://koalendar.com/e/meet-with-mark-bernstein-koaSc6kO?embed=true', '_blank')}>
										Book a Call With Our CEO
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
		)
	};

	return (
		<AnimatedPage>
			<div className="td250 container py1rem shadow w100 df">
				<div className="w80 mr1rem gg1rem mt1rem bg1 pt2rem pb2rem">
					<div className="ttc df tac">
						{tabIndex == "Lead Qualification Widget"
							? <div>
								<img className="w10 mb0-5rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
								<p className="w100 text-gradient fs1-25rem fw600">FREE Lead Qualification Widget Template</p>
								<p onClick={() => dispatch(toggleModal({ open: 'howItWorks' }))} className="cp w100 text-gradient fs1-25rem fw600 tdu">How does it work?</p>
							</div>
							: <h1 className="w100 text-gradient fs2rem lh2">{tabIndex}</h1>
						}
						<Link to={user?.['role'] == 'Advisor' ? `/advisor-dashboard#free-tools` : user?.['role'] == 'Investor' ? `/investor-dashboard` : `/dashboard`}
							className="pa tac dashboard-btn c1 bg4 bg-gradient-hover br30px fs0-75rem fw500 w120px px2rem py0-5rem df aic jcc">
							<i className="icon mr1rem">
								<svg width="15" height="15" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M6.79039 11.7808L2.97655 7.99985L6.79039 4.21887C7.09858 3.91299 7.06263 3.4485 6.70945 3.18134C6.35626 2.91443 5.82021 2.94581 5.51145 3.25144L1.20972 7.51624C0.930093 7.7932 0.930093 8.20671 1.20972 8.48368L5.5115 12.7485C5.67959 12.9147 5.91479 13 6.15137 13C6.34949 13 6.54841 12.9402 6.70945 12.8184C7.06268 12.5512 7.09887 12.0867 6.79039 11.7808Z" fill="white" stroke="white" stroke-width="0.5" />
									<path d="M14.1607 7.27344H2.20665C1.79909 7.27344 1.46875 7.60374 1.46875 8.01133C1.46875 8.4189 1.79909 8.74923 2.20665 8.74923H14.1607C16.3038 8.74923 18.0472 10.4926 18.0472 12.6355C18.0472 14.7784 16.3038 16.5218 14.1607 16.5218H3.19053C2.78297 16.5218 2.45264 16.8521 2.45264 17.2597C2.45264 17.6673 2.78297 17.9976 3.19053 17.9976H14.1607C17.1172 17.9976 19.523 15.592 19.523 12.6355C19.523 9.67899 17.1175 7.27344 14.1607 7.27344Z" fill="white" stroke="white" stroke-width="0.5" />
								</svg>
							</i>
							Back
						</Link>
					</div>
					{tabIndex == "Companies House Core Info" && <>{companiesHouseCore()}</>}
					{tabIndex == "Location & Web Site & Socials" && <>{locationWebsite()}</>}
					{tabIndex == "Director's Info" && <>{directorsInfo()}</>}
					{user?.['role'] == 'Advisor'
						? <>
							{tabIndex == "Advisor Permissions" && <>{investorPermissions()}</>}
						</>
						: <>
							{tabIndex == "Investor Permissions" && <>{investorPermissions()}</>}
							{tabIndex == "Fund Information & Investment Criteria" && <>{ourFundsPreferredInvestmentCriteria()}</>}
							{tabIndex == "Lead Qualification Widget" && <>{leadQualificationWidget()}</>}
							{tabIndex == "Preferred Contact For Leads" && <>{preferredContactForLeads()}</>}
						</>
					}
				</div>
				{getSidebarHTML()}
			</div>

			{/* Add Contact */}
			<Modal open={modal['open'] == 'addContact'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr oya">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<img className="w20 pa t0 l0 m1rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
					<h1 className="text-gradient fw600 pb0-5rem">Add Investor Team Member</h1>
					<div class="df fdc gg1rem px2rem">
						<div className='form-group df jsc'>
							<div className='img-upload-area pr'>
								<div className='img-box pr'>
									<img src={newContact['profilePicture'] || require('../../../../images/profile-photo.jpeg')} alt="Profile" className="profile-img w7rem h7rem advisor-profile shadow" />
									<div className='input-file'>
										<span className='add-btn bg5' onClick={() => { setIsImageModal(true), setUploadType('newContactImage'), setCurrentImage('') }}>
											<svg viewBox="0 0 487 487" className="zi3">
												<g>
													<path d="M308.1,277.95c0,35.7-28.9,64.6-64.6,64.6s-64.6-28.9-64.6-64.6s28.9-64.6,64.6-64.6S308.1,242.25,308.1,277.95z
													M440.3,116.05c25.8,0,46.7,20.9,46.7,46.7v122.4v103.8c0,27.5-22.3,49.8-49.8,49.8H49.8c-27.5,0-49.8-22.3-49.8-49.8v-103.9
													v-122.3l0,0c0-25.8,20.9-46.7,46.7-46.7h93.4l4.4-18.6c6.7-28.8,32.4-49.2,62-49.2h74.1c29.6,0,55.3,20.4,62,49.2l4.3,18.6H440.3z
													M97.4,183.45c0-12.9-10.5-23.4-23.4-23.4c-13,0-23.5,10.5-23.5,23.4s10.5,23.4,23.4,23.4C86.9,206.95,97.4,196.45,97.4,183.45z
													M358.7,277.95c0-63.6-51.6-115.2-115.2-115.2s-115.2,51.6-115.2,115.2s51.6,115.2,115.2,115.2S358.7,341.55,358.7,277.95z"/>
												</g>
											</svg>
										</span>
									</div>
								</div>
							</div>
						</div>
						<p className='mb0-5rem fs0-75rem tal df jcfs w100'>Please note that Contact can only be added if all fields are completed</p>
						<div className="dg gtcr2-md gg1rem">
							<div class="pr">
								<input
									name="firstName"
									type="text"
									placeholder="First Name"
									className="pl4rem shadow bg5 w100 br5px p1rem"
									value={newContact['firstName'] || ''}
									onChange={(event) => handleNewContactChange(event)}
								/>
								<svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
									<path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
								</svg>
							</div>
							<div class="pr">
								<input
									name="lastName"
									type="text"
									placeholder="Last Name"
									className="pl4rem shadow bg5 w100 br5px p1rem"
									value={newContact['lastName'] || ''}
									onChange={(event) => handleNewContactChange(event)}
								/>
								<svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
									<path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
								</svg>
							</div>
						</div>
						<div className="pr">
							<input
								name="email"
								type="email"
								placeholder="Email"
								className="pl4rem shadow bg5 w100 br5px p1rem"
								value={newContact['email'] || ''}
								onChange={(event) => handleNewContactChange(event)}
							/>
							<svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 512 512">
								<path d="M256.47 352h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V400a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V183.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52zM464 64H48a48 48 0 0 0-48 48v19a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4 23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131v-19a48 48 0 0 0-48-48z" className="fa-secondary"></path>
								<path d="M512 131v52.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V131a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.69 12.12 49.75 41.4 72.93 41.4h.94c23.18 0 56.24-29.28 72.93-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131z" className="fa-primary"></path>
							</svg>
						</div>
						<div className="pr">
							<input
								name="phone"
								type="text"
								placeholder="Phone Number"
								className="pl4rem shadow bg5 w100 br5px p1rem"
								value={newContact['phone']}
								onChange={(event) => handleNewContactChange(event)}
							/>
							<svg className="center-vertically f3 pa l0 ml1rem w2rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
								<path className="fa-primary" d="M352 48V384H32V48C32 21.5 53.5 0 80 0h224C330.5 0 352 21.5 352 48z" />
								<path className="fa-secondary" d="M32 384v80C32 490.5 53.5 512 80 512h224c26.5 0 48-21.5 48-48V384H32zM240 447.1C240 456.8 232.8 464 224 464H159.1C151.2 464 144 456.8 144 448S151.2 432 160 432h64C232.8 432 240 439.2 240 447.1z" />
							</svg>
						</div>
						<div className="pr">
							<input
								name="linkedIn"
								type="url"
								placeholder="LinkedIn"
								className="pl4rem shadow bg5 w100 br5px p1rem"
								value={newContact['linkedIn']}
								onChange={(event) => handleNewContactChange(event)}
							/>
							<svg className="center-vertically f3 pa l0 ml1rem w2rem" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310">
								<g>
									<path id="XMLID_802_" d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
										C77.16,101.969,74.922,99.73,72.16,99.73z"/>
									<path id="XMLID_803_" d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
										c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"/>
									<path id="XMLID_804_" d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
										c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
										c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
										C310,145.43,300.549,94.761,230.454,94.761z"/>
								</g>
							</svg>
						</div>
						<div className='df jcsb tar mt1rem'>
							<button className="pr shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem w30 usn cp ttu bsbb tac" onClick={() => addNewContact()}>
								Add Contact
								<span className="tooltip-area-custom cp" style={{ top: '-8px', right: '-8px' }}>
									<i className="icon">
										<img className="h2rem" src={require(`../../../../images/info-icon.svg`)} alt="" />
										<div className="overlay-box" style={{ left: 'auto', top: '0', right: '-380px' }}>
											<p className="fs0-75rem c6">We want to provide a great experience to both investors and applicant companies. We recognize that you may not be the correct contact but ask you that you complete all the fields for any individual named as replacement. We ask that the contact email not be enquiries@vcname.com etc. We want our curated leads to go to a named individual for assessment.<br /><br /> Thank you,<br /> Diligentsia Team</p>
										</div>
									</i>
								</span>
							</button>
							<div class="df jce">
								<div>
									<div class="fs0-75rem">
										<label>Designate as main recipient for leads</label>
										<div className='switch-box'>
											<Switch
												name="isMainRecipient"
												className="success"
												checked={isMainRecipient}
												onChange={(event) => handleNewContactChange(event)}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>

			{/* Filing Read More */}
			<Modal open={modal['open'] == 'filingReadMore'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 px3rem py2rem bsbb df fdc m1rem maw600px br5px pr">
					<div className='avtar-area df jcc'>
						<img className="h2rem" src={require(`../../../../images/filinghistory.png`)} alt="" />
					</div>
					<h3 className='mt1rem text-gradient fs1-5rem tac'>Filing History</h3>
					<button className="close-popup" onClick={() => { dispatch(toggleModal({ open: '' })); setFilingReadMore({}) }}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<div className='group-inputs mt1rem'>
						<p className='my1rem ttc'><b>Category:</b> {filingReadMore['category'] ? filingReadMore['category'] : '-'}</p>
						<p className='my1rem ttc'><b>Description:</b> {filingReadMore['description'] ? filingReadMore['description'] : '-'}</p>
						<p className='my1rem ttc'><b>Bar Code:</b> {filingReadMore['barcode'] ? filingReadMore['barcode'] : '-'}</p>
						<p className='my1rem ttc'><b>Transaction Id:</b> {filingReadMore['transaction_id'] ? filingReadMore['transaction_id'] : '-'}</p>
						<p className='my1rem ttc'><b>Date:</b> {filingReadMore['date'] ? filingReadMore['date'] : '-'}</p>
						<p className='my1rem ttc'><b>Document:</b> {filingReadMore['Location'] ? <a href={filingReadMore['Location']} target='_blank' className='tdu ttu'>View Document</a> : '-'}</p>
					</div>
				</div>
			</Modal>

			{/* Officers Read More */}
			<Modal open={modal['open'] == 'officersReadMore'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 px3rem py2rem bsbb df fdc m1rem maw600px br5px pr">
					<div className='avtar-area df jcc'>
						<img className="h2rem" src={require(`../../../../images/officersavatar.png`)} alt="" />
					</div>
					<h3 className='mt1rem text-gradient fs1-5rem tac'>Officers</h3>
					<button className="close-popup" onClick={() => { dispatch(toggleModal({ open: '' })); setOfficersReadMore({}) }}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<div className='group-inputs mt1rem'>
						<p className='my1rem ttc'><b>Name:</b>  {officersReadMore['name'] ? officersReadMore['name'] : '-'}</p>
						<p className='my1rem ttc'><b>Role:</b>  {officersReadMore['officer_role'] ? officersReadMore['officer_role'] : '-'}</p>
						<p className='my1rem ttc'><b>Address:</b> {officersReadMore['address']
							? <>{officersReadMore['address']['premises'] && officersReadMore['address']['premises']}
								{officersReadMore['address']['address_line_1'] && ', ' + officersReadMore['address']['address_line_1']}
								{officersReadMore['address']['address_line_2'] && ', ' + officersReadMore['address']['address_line_2']}
								{officersReadMore['address']['locality'] && ' - ' + officersReadMore['address']['locality']}
								{officersReadMore['address']['region'] && ', ' + officersReadMore['address']['region']}
								{officersReadMore['address']['country'] && ', ' + officersReadMore['address']['country']}
							</>
							: '-'
						}</p>
						<p className='my1rem ttc'><b>Nationality:</b>  {officersReadMore['nationality'] ? officersReadMore['nationality'] : '-'}</p>
						<p className='my1rem ttc'><b>Occupation:</b>  {officersReadMore['occupation'] ? officersReadMore['occupation'] : '-'}</p>
						<p className='my1rem ttc'><b>DOB:</b>  {officersReadMore['date_of_birth'] ? officersReadMore['date_of_birth']['month'] + '/' + officersReadMore['date_of_birth']['year'] : '-'}</p>
					</div>
				</div>
			</Modal>

			{/* Controls Read More */}
			<Modal open={modal['open'] == 'controlReadMore'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 px3rem py2rem bsbb df fdc m1rem maw600px br5px pr">
					<div className='avtar-area df jcc'>
						<img className="h2rem" src={require(`../../../../images/officersavatar.png`)} alt="" />
					</div>
					<h3 className='mt1rem text-gradient fs1-5rem tac'>Persons with Significant Control</h3>
					<button className="close-popup" onClick={() => { dispatch(toggleModal({ open: '' })); setControlReadMore({}) }}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<div className='group-inputs mt1rem'>
						<p className='my1rem ttc'><b>Name:</b>  {controlReadMore['name'] ? controlReadMore['name'] : '-'}</p>
						<p className='my1rem ttc'><b>Controls:</b>
							{controlReadMore['natures_of_control'] && controlReadMore['natures_of_control'].length > 0
								? <>{controlReadMore['natures_of_control'].map((control, idx) => (
									<ul key={idx}><li>{control}</li></ul>
								))}</>
								: '-'}</p>
						<p className='my1rem ttc'><b>E-Tag:</b>  {controlReadMore['etag'] ? controlReadMore['etag'] : '-'}</p>
						<p className='my1rem ttc'><b>Notified Date:</b>  {controlReadMore['notified_on'] ? controlReadMore['notified_on'] : '-'}</p>
						<p className='my1rem ttc'><b>Address:</b> {controlReadMore['address']
							? <>{controlReadMore['address']['premises'] && controlReadMore['address']['premises']}
								{controlReadMore['address']['address_line_1'] && ', ' + controlReadMore['address']['address_line_1']}
								{controlReadMore['address']['address_line_2'] && ', ' + controlReadMore['address']['address_line_2']}
								{controlReadMore['address']['locality'] && ' - ' + controlReadMore['address']['locality']}
								{controlReadMore['address']['region'] && ', ' + controlReadMore['address']['region']}
								{controlReadMore['address']['country'] && ', ' + controlReadMore['address']['country']}
							</>
							: '-'
						}</p>
						<p className='my1rem ttc'><b>Nationality:</b>  {controlReadMore['nationality'] ? controlReadMore['nationality'] : '-'}</p>
						<p className='my1rem ttc'><b>DOB:</b>  {controlReadMore['date_of_birth'] ? controlReadMore['date_of_birth']['month'] + '/' + controlReadMore['date_of_birth']['year'] : '-'}</p>
					</div>
				</div>
			</Modal>

			{/* Charge Read More */}
			<Modal open={modal['open'] == 'chargeReadMore'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 px3rem py2rem bsbb df fdc m1rem maw600px br5px pr">
					<div className='avtar-area df jcc'>
						<img className="h2rem" src={require(`../../../../images/officersavatar.png`)} alt="" />
					</div>
					<h3 className='mt1rem text-gradient fs1-5rem tac'>Charges</h3>
					<button className="close-popup" onClick={() => { dispatch(toggleModal({ open: '' })); setChargeReadMore({}) }}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<div className='group-inputs mt1rem'>
						<p className='my1rem ttc'><b>Persons Entitled:</b>  {chargeReadMore['persons_entitled'] ? chargeReadMore['persons_entitled'][0]['name'] : '-'}</p>
						<p className='my1rem ttc'><b>Charge Description:</b>  {chargeReadMore['classification'] ? chargeReadMore?.['classification']?.['description'] : '-'}</p>
						<p className='my1rem ttc'><b>Status:</b>  {chargeReadMore['status'] ? chargeReadMore['status'] : '-'}</p>
						<p className='my1rem ttc'><b>E-Tag:</b>  {chargeReadMore['etag'] ? chargeReadMore['etag'] : '-'}</p>
						<p className='my1rem ttc'><b>Charge Number:</b>  {chargeReadMore['charge_number'] ? chargeReadMore['charge_number'] : '-'}</p>
						<p className='my1rem ttc'><b>Delivered On:</b>  {chargeReadMore['delivered_on'] ? chargeReadMore['delivered_on'] : '-'}</p>
						<p className='my1rem ttc'><b>Satisfied On:</b>  {chargeReadMore['satisfied_on'] ? chargeReadMore['satisfied_on'] : '-'}</p>
						<p className='my1rem ttc'><b>Short Particulars:</b>  {chargeReadMore['particulars'] ? chargeReadMore?.['particulars']?.['description'] : '-'}</p>
						<p className='my1rem ttc'><b>Amount secured description:</b>  {chargeReadMore['secured_details'] ? chargeReadMore?.['secured_details']?.['description'] : '-'}</p>
						<p className='my1rem ttc'><b>Transaction filing type:</b>  {chargeReadMore['transactions'] ? chargeReadMore?.['transactions']?.['filing_type'] : '-'}</p>
						<p className='my1rem ttc'><b>Transaction filing Delivered On:</b>  {chargeReadMore['transactions'] ? chargeReadMore?.['transactions']?.['delivered_on'] : '-'}</p>
					</div>
				</div>
			</Modal>

			{/* Upload CV Data */}
			<Modal open={modal['open'] == 'uploadCvData'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => closeDirectorPopup()}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs1-25rem fw600 mt1rem">Director Info Update</h1>
					<div className='group-inputs mt2rem'>
						<h3 className='fs1rem fw500 mb1rem'>Upload CV Here</h3>
						<label for="fileUpload" className="w100 bsbb bg5 br5px df jcc aic p1rem bsd bo6 bw2px cp">
							<span className="pen fs1rem fw500 tac">Upload CV</span>
							<input onChange={handleCvChange} className="dn" id="fileUpload" type="file" multiple />
						</label>
						{cvData[director['_id']] && <div className="df fww mt0-5rem">
							{cvData[director['_id']] && <a className="df jcc aic br25px lh1 bg3 c1 px0-5rem fw600 mr0-5rem mb0-5rem" target="_blank" href={cvData[director['_id']]?.location}>
								<span className="py0-5rem px0-5rem">{cvData[director['_id']]?.name}</span>
							</a>}
						</div>}
						<p className='fs1rem fw500 mt1rem mb0-5rem'>LinkedIn Profile</p><br />
						<input id="LinkedinUrl" name="LinkedinUrl" type="url" placeholder="Please add LinkedIn url" value={formData['linkedIn'] || ''} onChange={(e) => setFormData({ ...formData, linkedIn: e.target.value })}
							className="w100 show-input shadow bg5 br5px p1rem mb1rem" />

						<div className='df jcse'>
							<span onClick={() => handleSubmit()} className="shadow bg3 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem">Submit</span>
						</div>
					</div>
				</div>
			</Modal>

			{/* Delete File */}
			<Modal open={modal['open'] == 'deleteFiles'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs2rem fw600 my1rem">Delete File</h1>
					<h2 className="c4 fs1rem fs1-5rem-sm ttu w100 tac mb1rem">Are you sure you want to delete this file?</h2>
					<p className="fs1-25rem fw500 mb1rem">{attachment && attachment.originalname}</p>
					<span onClick={() => confirmDeleteFiles()} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp ttu w100 bsbb tac mb1rem df jcc aic">Delete File</span>
					<span onClick={() => dispatch(toggleModal({ open: '' }))} className="c3-hover td250 fs1-25rem cp">Cancel</span>
				</div>
			</Modal>

			{/* Audit Logs */}
			<Modal open={modal['open'] == 'auditLogs'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs1-5rem fw600 tac ttu">{company['cName']} Company Audit Log</h1>
					<h2 className="c4 fs0-75rem ttu w100 tac mb1rem">See the history of Company Audit Updated</h2>
					<div className="ma oya dg gg0-5rem mb2rem w100">
						<div className='df'>

							<span className='fs1rem ml1rem'><b>Company Details</b></span>
						</div>
						<div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
							<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Name:</span> {company['cName']}</p>
							{company?.['creatorId'] && <p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Owner:</span> {company['creatorId']['firstName'] + ' ' + company['creatorId']['lastName']}</p>}
							<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Created At:</span> {moment(company['creaedAt']).format('ll')}</p>
							<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Payment Source:</span> {company['paymentSource']}</p>
							<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Stage of development:</span> {company['cStage']}</p>
							<p className='df fw500 ttc'><span className='fw600 mr0-5rem'>Company Objectives:</span> {company['testType']}</p>
						</div>
						{auditLogList && auditLogList.length > 0 && auditLogList.map(log => {
							return (
								<div className="df my0-5rem">
									{(log?.['userId']?.['profile'] || log?.['userId']?.['profilePicture'])
										&& <img className={`ass ml0-5rem br100 w2rem h2rem client-profile`} src={(log?.['userId']?.['profile'] || log?.['userId']?.['profilePicture'])} data-tooltip={log?.['userId'].firstName ? (log?.['userId'].firstName + " " + log?.['userId'].lastName) : "Register to show your name here"} />
										|| <span className={`ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-75rem client-profile`}>{log?.['userId']?.['firstName']?.[0] || "?"}{log?.['userId']?.['lastName']?.[0] || "?"}</span>
									}
									<div className="w100">
										<span className='fs0-75rem ml1rem'><b>{log['message']}</b> at <b>{new Date(log.timestamp).toLocaleString()}</b> by <b>{log?.['userId']['firstName']} {log?.['userId']['lastName']}</b></span>
										{/* Company Info Logs */}
										{log['payload']['update'] &&
											<div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
												<p className="df fw500 fs1rem">Changes to {company['cName']} - Company Details:</p>
												<div class="df w100">
													<div class="mr0-5rem">
														{Object.keys(log['payload']['update']).map(key => {
															return <p className="fw600">{companyKeys[key]}:</p>
														})}
													</div>
													<div>
														{Object.values(log['payload']['update']).map(value => {
															return <p>{typeof (value) == 'string' ? value : (typeof (value) == 'boolean' && value) ? 'True' : 'False'}</p>
														})}
													</div>
												</div>
											</div>
										}
										{/* Company Group Logs */}
										{log['payload']?.['data']?.['childUpdates'] && log['payload']?.['data']?.['childUpdates'].length > 0 &&
											<div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
												<p className="df fw500 fs1rem">{log['payload']['data']['type'] && log['payload']['data']['type']} Group Info:</p>
												{log['payload']?.['data']?.['childUpdates'].map(data => {
													return <>
														{companies && companies.length > 0 && companies.map(company => {
															if (company['_id'] == log['payload']?.['data']?.['parentId']) {
																if (company['childrenCompanies']) {
																	for (let childCompnay of company['childrenCompanies']) {
																		if (childCompnay['_id'] == data['companyId']) {
																			return <p><span className='fw600'>Company Name:</span> {childCompnay['cName']}</p>
																		}
																	}
																}
															}
														})}
														<p><span className='fw600'>Group:</span> {data['group']}</p>
														<p><span className='fw600'>Percentage:</span> {data['percentage']}%</p>
													</>
												})}
											</div>
										}
										{/* Company Directors Logs */}
										{log['payload']['directorId'] &&
											<div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
												<p className="df fw500 fs1rem">Changes to {log['payload']['directorId']['name']} - Director's Info:</p>
												<p className='df fw500'><span className='fw600 mr0-5rem'>CV:</span>
													{log['files'].length > 0 && log['files'].map(file => (
														<a className="fw400 mr0-5rem fs0-75rem tdu" target="_blank" href={file?.location}><span>{file?.originalname}</span></a>
													))}
												</p>
												<p className='df fw500'><span className='fw600 mr0-5rem'>LinkedIn:</span>
													{log['payload']['linkedIn'] && log['payload']['linkedIn'] !== 'undefined'
														? <a className="fw400 mr0-5rem fs0-75rem tdu" target="_blank" href={log['payload']['linkedIn']}>{log['payload']['linkedIn']}</a> : '-'}
												</p>
											</div>
										}
									</div>
								</div>
							)
						})
						}
					</div>
				</div>
			</Modal>

			{/* Upload Image */}
			<Modal open={isImageModal} onClose={() => setIsImageModal(false)} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 bsbb df fdc m1rem maw600px br5px pr">
					<div className='top-header py1rem px2rem df jcsb aic' style={{ backgroundColor: '#FFF6A680', borderRadius: '8px 8px 0 0' }}>
						<h4 className='fs1-5rem c15 fw500 ttc'>Upload Profile Picture</h4>
						<img className="w60px" src={require(`../../../../images/logo-icon.svg`)} alt="TODO" />
					</div>
					<div className='df fdc p3rem'>
						{currentImage == ''
							? <label for="profile" className="w100 bsbb bg5 br5px tac p1rem bsd bo6 bw2px cp pr upload-pic-area">
								<span className="pen fs1rem fw500 tac">Upload picture</span><br />
								<div className='input-btn pa t0 w100 op0 l0 cp'>
									<input className='w100 l0 h5rem cp' onChange={(e) => handleImage(e)} id="currentImage" type="file" accept="image/jpeg, image/jpg, image/png" />
								</div>
								<label >Max file size 5MB</label>
							</label>
							: <div className='img-box pr'>
								<Cropper
									ref={cropper}
									guides={false}
									aspectRatio={1 / 1}
									className='cropper-settings'
									src={currentImage}
								/>
							</div>
						}
						<div className="df jcse">
							<span onClick={() => setIsImageModal(false)} className="shadow bg4 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem">Cancel</span>
							<span onClick={() => uploadImage()} className="shadow bg3 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem">
								{Object.keys(currentImage).length == 0
									? 'Upload'
									: 'Crop & Upload'
								}
							</span>
						</div>
					</div>
				</div>
			</Modal>

			{/* How It Works */}
			<Modal open={modal['open'] == 'howItWorks'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="bg1 shadow w100 pt4rem pb0-5rem px2rem bsbb df fdc maw900px pr br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<img className="h2rem pa t0 l0 m1rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
					<h1 className="text-gradient fw600 pb0-5rem">How does it work?</h1>
					<div className="tac my0-5rem">
						<ul className='how-it-works taj'>
							<li className='mb0-5rem'>Qualify unsolicited approaches to your Web site for funding</li>
							<li className='mb0-5rem'>Add FREE Diligentsia widget to your website In minutes</li>
							<li className='mb0-5rem'>Fully automated - saves your staff time on qualification of poor fit approaches</li>
							<li className='mb0-5rem'>Reserves human interaction for high value target company interactions - still very important</li>
							<li className='mb0-5rem'>Automated reporting - completeness, fit</li>
							<li className='mb0-5rem'>Sits within a complete set of automated modules to improve investment success Rates</li>
							<li className='mb0-5rem'>Valuable report for the enquiring Company - its approach not wasted even if unsuccessful - generates goodwill for your firm</li>
							<li className='mb0-5rem'>Receive qualified leads that appear to meet your investment criteria</li>
							<li className='mb0-5rem'>You create FREE questionnaire in  minutes and Attach to your web site</li>
							<li className='mb0-5rem'>You can add/edit sections and questions to Bespoke it to your firm's requirements</li>
							<li className='mb0-5rem'>Companies approaching your firm for funding Via your web site are directed to complete the questionnaire</li>
							<li className='mb0-5rem'>Automated emails can be generated - Interested/thank you but not a close enough fit</li>
							<li className='mb0-5rem'>Process workflow management from your Investor dashboard</li>
							<li className='mb0-5rem'>If you wish to progress, other modules can Be added to that company's account eg:
								<ul style={{ listStyleType: "initial" }}>
									<li>Detailed Due Diligence Questionnaire</li>
									<li>Warranty Disclosures</li>
									<li>Directors' Questionnaires</li>
									<li>Data Repository</li>
									<li>Business Audit</li>
								</ul>
							</li>
						</ul>
					</div>
					{/* <button onClick={() => dispatch(toggleModal({ open: '' }))} className="h100 mt0-5rem mxa shadow bg3 bg4-hover c1 td250 br5px px1rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
						Let's go!
					</button> */}
				</div>
			</Modal>

		</AnimatedPage >
	);
}