import { Link } from 'react-router-dom';
import * as ROUTES from "../constants/routes";
import React, { useRef, createRef, useState } from 'react';

import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

import AnimatedPage from '../components/AnimatedPage';
import PricingColumn from "../components/PricingColumn";
import { COMPONENT_REF } from './landingPage/homePage/Companies';
import SmoothScrollingButton from '../components/SmoothScrollingButton';
// import PricingSection from '../components/landingPage/PricingSection';
import { FREE_PLAN, LITE_PLAN, PRO_PLAN, PRO_PLUS_PLAN, PORTFOLIO_PRO_PLAN } from '../constants/pricingPlane';


// Pricing constant
const PRICING_CONSTANT =
{
    footer: <div className='w100 df jcs fdc aic'>
        <div className="w90 df jcs">** = “Expansion Packs are additional to Pro subscription and are charged separately </div>
        <div className="w90 df jcs">*** = For companies subscribing directly, if introduced by your advisor or investor under Diligentsia Pro, please do not subscribe here but await invite email from your contact</div>
    </div>,
    points: [FREE_PLAN, LITE_PLAN, PRO_PLAN, PRO_PLUS_PLAN, PORTFOLIO_PRO_PLAN]
};

const INVESTMENT_READY_CONSTANT = [
    {
        title: <>Draft Warranty <br />Disclosures</>,
        image: require('../../images/landingpage/pricing-investment-2.png'),
        imageStyle: { width: "98%" },
        button: {
            price: '£500'
        },
        identifier: 'section-1'
    },
    {
        title: 'Draft Directors’ Questionnaires',

        image: require('../../images/landingpage/pricing-investment-1.png'),
        imageStyle: { width: "100%" },
        button: {
            price: '£500'
        },
        identifier: 'section-2'
    },
    {
        title: <>Investor Lead <br />Generation Module</>,
        image: require('../../images/landingpage/pricing-investment-3.png'),
        imageStyle: { width: "94%" },
        button: {
            price: '£500',
            subHeading: 'Companies'
        },
        identifier: 'section-3'
    },
];

const GROWTH_READY_CONSTANT = [
    {
        title: 'FREE KPI Report',
        image: require('../../images/landingpage/pricing-growth-1.png'),
        imageStyle: { width: "100%" },
        button: {
            price: 'FREE'
        },
        identifier: 'section-1'
    },
    {
        title: 'DD Checklist Builder',
        image: require('../../images/landingpage/pricing-growth-2.png'),
        imageStyle: { width: "98%" },
        button: {
            price: '£500'
        },
        identifier: 'section-2'
    },
    {
        title: 'Software Development',
        image: require('../../images/landingpage/pricing-growth-3.png'),
        imageStyle: { width: "98%" },
        button: {
            price: '£250',
        },
        identifier: 'section-3'
    },
    {
        title: 'ESG',
        image: require('../../images/landingpage/pricing-growth-4.png'),
        imageStyle: { width: "98%" },
        button: {
            price: '£250',
        },
        identifier: 'section-4'
    },
];

const PricingPlans = () => {

    let [tab, setTab] = useState("Companies");

    const refs = useRef(COMPONENT_REF.map(() => createRef()));

    const onClickHandler = () => {
        window.open('https://koalendar.com/e/meet-with-mark-bernstein-koaSc6kO?embed=true', '_blank')
    }

    return (
        <AnimatedPage>
            <div className="poppins-font-style bg1 pricing-plan-page">
                <SmoothScrollingButton />
                <div className="df w100 pt2rem">
                    <div className='w60 fs3-125rem fw500 c15 df aic' style={{ lineHeight: "3.45rem", paddingLeft: '13.5rem' }} >
                        We’ve got a plan that’s <br />perfect for you
                    </div>
                    <div className='w40 pr df ais bscov' style={{ backgroundImage: `url(${require('../../images/landingpage/pricing-banner-back.png')})` }}>
                        <img src={require('../../images/landingpage/pricing-dili-logo.png')} alt='img' className="w80 ofcon" />
                        <img src={require('../../images/landingpage/sphere.png')} alt='img' className="pa ar" style={{ bottom: "23%", right: "46%", maxWidth: "4rem" }} />
                    </div>
                </div>

                {/* Pricing section */}
                {/* <PricingSection pricingTitle="p-section" data={PRICING_CONSTANT} refs={refs} /> */}
                <div className='df jcc fdc aic bg20 py3rem pb5rem pricing-tabs oh'>
                    <div className={`pb1rem df jcc fdc tslp op1 tty0`}>
                        <p className="df fw500 fs2-125rem c15 tac" style={{ lineHeight: '62.2px' }}>
                            Our <span className="c4 mx0-5rem">Pricing</span> Plans
                        </p>
                        <p className='fw400 fs1rem c15 mt0-5rem mb1rem' style={{ lineHeight: '#151313' }}>
                            Choose the right Diligentsia Plan for you
                        </p>
                    </div>

                    <TabContext className="w100" value={tab} >
                        <TabList onChange={(e, value) => setTab(value)}>
                            <Tab className="fs1rem fw600" label="Companies" value="Companies" />
                            <Tab className="fs1rem fw600" label="Advisor" value="Advisor" />
                            <Tab className="fs1rem fw600" label="Investor" value="Investor" />
                        </TabList>
                        <TabPanel className="w100" value="Companies">
                            <div className='df jcc fdc aic'>
                                <div className="pt10rem pb1-5rem c1 dg gtcr4-xl  gtcr2-md rg5rem cg2rem gg4rem-xl pricing-table-saf">
                                    <PricingColumn plan="Free" component="PricingPlan" />
                                    <PricingColumn plan="Lite" component="PricingPlan" />
                                    <PricingColumn plan="Pro" component="PricingPlan" />
                                    <PricingColumn plan="Pro PLUS" component="PricingPlan" />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel className="w100 tabs-portfolio" value="Advisor">
                            <div className='df jcc fdc aic'>
                                <div className="pt10rem pb1-5rem c1 dg gtcr4-xl  gtcr2-md rg5rem cg2rem gg4rem-xl pricing-table-saf pricing-advisor-table jcc ">
                                    <div className='df jcsa w100 mb7rem px4rem'>
                                        <p className='bg6 c1 fw600 br15px' style={{ padding: '9px 60px', background: '#b3b6c7' }}>For You</p>
                                        <p className='bg6 c1 fw600 br15px mr8rem' style={{ padding: '9px 40px', background: '#b3b6c7' }}>For You Clients</p>
                                    </div>
                                    <PricingColumn className="fist-pricing-plan" isAdvisor={true} isInvestor={false} plan="Portfolio Pro" component="PricingPlan" />
                                    {/* <div className='pricing-box pricing-box-mobile'></div> */}
                                    <PricingColumn plan="Pro" isAdvisor={true} isInvestor={false} component="PricingPlan" />
                                    <PricingColumn plan="Pro PLUS" component="PricingPlan" />
                                </div>
                            </div>
                            
                        </TabPanel>
                        <TabPanel className="w100 tabs-portfolio" value="Investor">
                            <div className='df jcc fdc aic'>
                                <div className="pt10rem pb1-5rem c1 dg gtcr4-xl  gtcr2-md rg5rem cg2rem gg4rem-xl pricing-table-saf pricing-advisor-table jcc ">
                                    <div className='df jcsa w100 mb7rem px4rem'>
                                        <p className='bg6 c1 fw600 br15px' style={{ padding: '9px 60px', background: '#b3b6c7' }}>For You</p>
                                        <p className='bg6 c1 fw600 br15px mr8rem' style={{ padding: '9px 40px', background: '#b3b6c7' }}>For You Portfolio</p>
                                    </div>
                                    <PricingColumn className="fist-pricing-plan" isAdvisor={false} isInvestor={true} plan="Portfolio Pro" component="PricingPlan" />
                                    {/* <div className='pricing-box pricing-box-mobile'></div> */}
                                    <PricingColumn plan="Pro" isAdvisor={false} isInvestor={true} component="PricingPlan" />
                                    <PricingColumn plan="Pro PLUS" component="PricingPlan" />
                                </div>
                            </div>
                        </TabPanel>
                    </TabContext>

                    <div className="w70 fw500 fs1rem pl9rem" style={{ lineHeight: '24px', fontSize: '12px', color: '#15131380' }}>
                        <div >* = 12 months purchase only</div>
                        {/* <div>  ** =</div> */}
                    </div>


                </div>

                {/* investment ready */}
                <div className="w100 df jcc fdc aic pt4rem">
                    <div className="w70 mt4rem df jcc aic fdc">
                        <p className="fw500 fs2-125rem c15 tac w60" style={{ lineHeight: '37.2px' }}>
                            Diligentsia <span className="c4"> AppStore</span> - Expansion Packs
                        </p>
                        <p className='fw400 fs1rem c15 mt0-5rem' style={{ lineHeight: '#151313' }}>
                            In-platform purchase - requires Pro subscription
                        </p>
                    </div>
                    <div className="w80 pt3rem px3rem bg1 df mt3rem mb3rem fdc pb4rem investment-box-pricing" style={{ borderRadius: '15px', background: "radial-gradient(70% 70% at 24.51% 50.12%, #F0F2FD 0%, #F0F2FD 100%)" }}>
                        <div className="fw500 fs1-5rem c15" style={{ lineHeight: '39.81px' }}>Investment/Exit Ready</div>
                        <div className="df jcsa w100 mt1rem">
                            {INVESTMENT_READY_CONSTANT.map((obj, i, arr) => {
                                return <div className="df fdc aic mt1rem" style={{ width: "30%" }}>
                                    <div className="fw500 fs1rem c14 w50 df jcc aic tac" style={{ lineHeight: '15px' }}>
                                        {obj?.title}
                                    </div>
                                    <img className="w100 mt1rem" src={obj?.image} style={{ ...obj?.imageStyle }} />
                                    <div className="df jcc bg1 fw700  fdc aic pt0-5rem pb0-5rem" style={{ width: '78%', height: '35px', border: "2px solid #748AFF", borderRadius: '54px' }}>
                                        <div className="fs1rem c3" style={{ marginTop: obj?.button?.subHeading && "5px", lineHeight: obj?.button?.subHeading && "8px" }}>{obj?.button?.price}</div>
                                        <div className={`${!obj?.button?.subHeading && 'vh'} fw500 c3`} style={{ fontSize: '9px' }}> {obj?.button?.subHeading}</div>
                                    </div>
                                </div>
                            })
                            }
                        </div>
                        <div className="w100 df jcc mt1-5rem">
                            <div className="w80 fw500 fs1rem c14 df jcc mt1-5rem" style={{ lineHeight: '25px', opacity: '0.6' }}>
                                * More modules coming soon
                            </div>
                        </div>
                    </div>
                    {/* Growth Ready */}
                    <div className="w80 pt3rem px3rem bg1 df mt3rem mb3rem fdc pb4rem investment-box-pricing" style={{ borderRadius: '15px', background: "radial-gradient(70% 70% at 24.51% 50.12%, #F0F2FD 0%, #F0F2FD 100%)" }}>
                        <div className="fw500 fs1-5rem c15 " style={{ lineHeight: '39.81px' }}>Growth Ready</div>
                        <div className="df jcsb w100 mt1rem">
                            {GROWTH_READY_CONSTANT.map((obj, i, arr) => {
                                return <div className="df fdc aic mt1rem" style={{ width: "25%" }}>
                                    <div className="fw500 fs1rem c14 w70 df jcc aic tac" style={{ lineHeight: '15px' }}>
                                        {obj?.title}
                                    </div>
                                    <img className="w100 mt1rem" src={obj?.image} style={{ ...obj?.imageStyle }} />
                                    <div className="df jcc bg1 fw700  fdc aic pt0-5rem pb0-5rem" style={{ width: '78%', height: '35px', border: "2px solid #748AFF", borderRadius: '54px' }}>
                                        <div className="fs1rem c3">{obj?.button?.price}</div>
                                        <div className={`${!obj?.button?.subHeading && 'vh'} fw500 c3`} style={{ fontSize: '9px', }}> {obj?.button?.subHeading}</div>
                                    </div>
                                </div>
                            })
                            }
                        </div>
                        <div className="w100 df jcc mt1-5rem">
                            <div className="w80 fw500 fs1rem c14 df jcc mt1-5rem" style={{ lineHeight: '25px', opacity: '0.6' }}>
                                *More modules coming soon
                            </div>
                        </div>
                    </div>
                </div>

                {/* action section*/}
                <div className="df w100 pt2rem pb2rem bscov pr" style={{ backgroundImage: `url(${require("../../images/landingpage/pricing-call-background.png")})` }}>
                    <div className='w60 fs3-125rem fw500 c15 df aic' style={{ lineHeight: "3.45rem", paddingLeft: '13.5rem' }}>
                        <h4 className="fs2rem fw400 mb2rem c15" style={{ lineHeight: "3.033rem", maxWidth: "42rem" }}>
                            Not sure which package is right for you? <br /> <span className="c4">Call us</span> - <span className="fw500">we are here to help!</span>
                        </h4>
                    </div>
                    <div className='w40 pr df aic jcc'>
                        <img src={require('../../images/landingpage/book-call.png')} className='w50 cp' alt='img'
                            onClick={() => window.open('https://koalendar.com/e/meet-with-mark-bernstein-koaSc6kO?embed=true', '_blank')}
                        />
                    </div>
                    <img src={require('../../images/landingpage/sphere.png')} alt='img' className="pa ar"
                        style={{ maxWidth: "3.5rem", left: '-22px', top: '-30px' }} />
                </div>

                {/* detail section*/}
                <div className="w100 df jcc">
                    <div className="w70 p1rem bg1 df mt3rem mb3rem " style={{
                        borderRadius: '40px', background: "radial-gradient(70% 70% at 24.51% 50.12%, #F5F7FF 0%, #E8ECFF 100%)"
                    }}>
                        <div className="w60 df fdc jcc" style={{ paddingLeft: '60px' }}>
                            <div className='fw500 fs2rem c15' style={{ lineHeight: '35.2px' }} >
                                Partner Program
                            </div>
                            <div className="fw400 fs1rem c14 mt1rem" style={{ lineHeight: '20px' }}>
                                Do you offer a specialist professional service?  Want to co-brand a <br />module?
                            </div>
                            <div>
                                <Link className="w40 bg4 df jcc br40px fw600 fs1rem c1 mt2-5rem py0-5rem" style={{ lineHeight: '30px' }} to={ROUTES.PARTNER_PROGRAM}>
                                    See Details
                                </Link>
                            </div>
                        </div>
                        <div className="w40 df jce">
                            <img src={require('../../images/landingpage/partner-details.png')} className="w60" />
                        </div>
                    </div>
                </div>
            </div>
        </AnimatedPage >
    )

}
export default PricingPlans;



