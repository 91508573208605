import moment from "moment";
import Modal from '@mui/material/Modal';
import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import { getRequest, showMessage } from "../../../config.js";
import { toggleModal } from "../../../reducers/modalReducer.js";

export default function Workflows({ company, setTab, setIsWorkflowReport, modulesForChecklist, setCompanyDashboardTab, }) {

	const dispatch = useDispatch();
	const navigation = useNavigate();

	const user = useSelector(state => state.user);
	const modal = useSelector(state => state.modal);

	let [selectedFeature, setSelectedFeature] = useState({});

	const navigateSection = async (tab, sectionNo) => {
		await setTab(tab);
		setTimeout(() => {
			let element = document.getElementById(sectionNo);
			if (element) element.scrollIntoView({ behavior: 'instant' }, true);
		}, 1000);
	};

	const redirectWorkflowSection = async (section) => {
		await setIsWorkflowReport(true);
		await navigateSection(`${section['moduleName']}-report`, section['sectionNo']);
		setCompanyDashboardTab('Main Dashboard');
	};

	const checklistModuleTooltip = (sections) => {
		return (
			<div className='cp module-tooltip-box'>
				<div className='hover-list'><img src={require('../../../../images/hover-list-icon.png')} className="pa" alt="list-img" /></div>
				{sections && sections.length > 0 &&
					<span className="tooltip-area">
						{sections.map((section, idx) => {
							let completeness = section?.['stats']?.['completionPercent'] ? section?.['stats']?.['completionPercent'] : 0;

							let riskColour;
							if (section?.['stats']?.['riskPercent'] < 38) riskColour = 7;
							else if (section?.['stats']?.['riskPercent'] > 62) riskColour = 9;
							else riskColour = 8;

							let hexColour = riskColour == 8 ? "#ddb64044" : riskColour == 9 ? "#dd404044" : "#40dda144";

							return <button type='button' key={idx} onClick={async () => redirectWorkflowSection(section)} style={{ background: `linear-gradient(90deg, ${hexColour} 0%, ${hexColour} ${completeness}%, #f0f0f8 ${completeness}%)` }} data-scroll-to={section['sectionNo']} className="overlay-box tal w100 tac cp c3-hover"><span>{section['sectionName']}</span></button>
						})}
					</span>
				}
			</div>
		)
	};

	const redirectWorkflowTab = async (moduleName) => {
		await setIsWorkflowReport(true);
		await setTab(`${moduleName}-report`);
		setCompanyDashboardTab('Main Dashboard');
	};

	const checklistModules = (modulesForChecklist) => {
		return Object.values(modulesForChecklist).map(module => {

			let sectionNames = [];
			let { moduleName } = module;
			if (company?.['modules']?.[`${moduleName}`]?.['sections'])
				for (const section of company['modules'][`${moduleName}`]['sections'])
					sectionNames.push(section['sectionName']);

			let stats = company['modules']?.[`${moduleName}`]?.['stats'];
			let isRedirect = false;
			if (company['modules']?.[`${moduleName}`]?.['stats'].completionPercent > 0) isRedirect = true;

			return <div className={`bo7 w25 keyfeature-wihte-item bg1 new-shadow br15px p2rem mx1rem my1-5rem zi9 pr bsbb advisor-bg-lite`}>
				<Link className="ttc fs1rem fw500 c15 lh2 zi1 w100">
					<div className='right-info-hyper-icon right-info-dashboard-area'>
						<div className='info-icon cp pr ml0-5rem'>
							<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="13.5" cy="13.5" r="13.5" transform="matrix(1 0 0 -1 0 27)" fill="#59B8FD" />
								<path d="M12.1581 11.0172C12.1652 10.5566 12.5413 10.1875 13.0019 10.1875C13.4624 10.1875 13.8386 10.5602 13.8456 11.0172L14.1269 18.5969C14.1445 18.8852 14.039 19.1664 13.8351 19.3844C13.6171 19.6164 13.3148 19.75 13.0019 19.75C12.689 19.75 12.3867 19.6164 12.1687 19.3844C11.9648 19.1664 11.8593 18.8852 11.8769 18.5969L12.1581 11.0172Z" fill="white" />
								<path d="M13 6.25C13.6213 6.25 14.125 6.75368 14.125 7.375C14.125 7.99632 13.6213 8.5 13 8.5C12.3787 8.5 11.875 7.99632 11.875 7.375C11.875 6.75368 12.3787 6.25 13 6.25Z" fill="white" />
							</svg>
							<div className='shadow overlay-box' >
								<p className='pl1rem py0-5rem fs1rem c15 fw400 tti'>{module['moduleDescription']}</p>
							</div>
						</div>
					</div>
					{company['modules']?.[`${moduleName}`]?.['sections'] && checklistModuleTooltip(company['modules']?.[`${moduleName}`]?.['sections'])}
					<Link to={isRedirect && `#${moduleName}-report`} onClick={() => { isRedirect ? redirectWorkflowTab(moduleName) : showMessage('Module not started yet!') }} class="cp w100 h100" style={{ minHeight: '130px' }}>
						{module?.['createdAt'] && <span className="tooltip-area style-two pa df aic jcsb w90">
							<div className="df"><p className="c12 fw300 mt2rem">{moment(module['createdAt']).format('L')}</p></div>
							<div className="df fdc jcc aic">
								<div className={`progress-bar workflow-report-progress ${stats?.workflowCompletionPercent > 75 ? 'workflow-progress-green' : stats?.workflowCompletionPercent > 26 ? 'workflow-progress-yellow' : stats?.workflowCompletionPercent > 1 ? ' workflow-progress-pink' : '  workflow-progress-grey'}`}
									style={{ width: 50, height: 50, borderRadius: '50%', margin: 0, border: '2px solid #F1F1F1' }}>
									<CircularProgressbar
										text={(stats?.workflowCompletionPercent || 0) + "%"}
										styles={buildStyles({
											strokeWidth: '0',
											textSize: '20px',
											trailColor: '#FBFBFB',
											stroke: 'transparent',
											strokeLinecap: 'butt',
											backgroundColor: '#FBFBFB',
											pathTransitionDuration: 0.5,
											dominantBaseline: 'ideographic',
										})}
									/>
								</div>
								<span style={{ marginBottom: "0", }} className="tac c15 fs0-75rem fw300">Workflow <br /> Completeness</span>
							</div>
						</span>}
						<div className='df aic'>
							<i className='icon-diligenstia mr1rem'>
								{module['moduleName'].includes('Cybersecurity')
									? <img className="w60px" src={require(`../../../../images/amicis-logo.png`)} alt="" />
									: module['moduleName'].includes('CFO')
										? <img className="w40px h40px" src={require(`../../../../images/cfo-center.png`)} alt="" />
										: <img className="w40px h40px" src={require(`../../../../images/advisor-dashboard/logo-advisor.svg`)} alt="" />
								}
							</i>
							<button className='cp fs1rem fw500 bg1 c4-hover c15 lh2 title-box-feature tal advisor-bg-lite'>{module['moduleName']}</button>
						</div>
					</Link>
				</Link>
			</div>
		});
	};

	return (
		<>
			<div className='sectionWrapper py1rem fs0-75rem pr anylist-builder-tab'>
				<div className='mt1rem bss px1rem py2rem br25px w100 keyfeature-box-portfolio anylist-checklist df fww mb3rem pr bsbb'>
					<h3 className="fs1-25rem c15 w100 pl1rem mb2rem">Workflows</h3>
					{/* Checklist Modules */}
					{modulesForChecklist && modulesForChecklist.length > 0 && checklistModules(modulesForChecklist)}
				</div>
			</div>
			{/* Key Feature See More Popup */}
			<Modal id="keyFeatureSeeMore" open={modal['open'] == 'openSelectedFeature'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw  df jcc aic advisor-dashboard-seemore" >
				<div className="poppins-font-style mah100 bg1 shadow w100 py2rem px4rem df fdc m1rem pr br10px pr" style={{ outline: 'none', paddingBottom: '2rem', maxWidth: '1350px' }}>
					<div className='df w100' >
						{selectedFeature != '' && (
							<div className="overlay-box">
								{/* {infoToolbox(selectedFeature)} */}
							</div>
						)}
					</div>
					<svg className="pa cp" onClick={() => dispatch(toggleModal({ open: '' }))} style={{ left: '650px', bottom: '-80px' }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
						<path d="M48.5626 11.4375C44.8913 7.76665 40.214 5.26685 35.1221 4.2542C30.0302 3.24156 24.7523 3.76155 19.9559 5.74843C15.1595 7.73531 11.06 11.0998 8.17569 15.4166C5.29142 19.7333 3.75195 24.8084 3.75195 30C3.75195 35.1917 5.29142 40.2667 8.17569 44.5835C11.06 48.9002 15.1595 52.2647 19.9559 54.2516C24.7523 56.2385 30.0302 56.7585 35.1221 55.7458C40.214 54.7332 44.8913 52.2334 48.5626 48.5625C51.0004 46.1249 52.9342 43.231 54.2535 40.0461C55.5729 36.8611 56.252 33.4474 56.252 30C56.252 26.5526 55.5729 23.1389 54.2535 19.954C52.9342 16.769 51.0004 13.8751 48.5626 11.4375ZM39.3751 36.6375C39.5624 36.8129 39.7117 37.0249 39.8138 37.2603C39.9159 37.4958 39.9686 37.7496 39.9686 38.0063C39.9686 38.2629 39.9159 38.5168 39.8138 38.7522C39.7117 38.9876 39.5624 39.1996 39.3751 39.375C39.1999 39.5488 38.9921 39.6863 38.7636 39.7796C38.5352 39.8729 38.2906 39.9202 38.0438 39.9188C37.5519 39.9167 37.0805 39.7214 36.7313 39.375L30.0001 32.6438L23.3626 39.375C23.0133 39.7214 22.5419 39.9167 22.0501 39.9188C21.8033 39.9202 21.5587 39.8729 21.3302 39.7796C21.1018 39.6863 20.894 39.5488 20.7188 39.375C20.3696 39.0237 20.1736 38.5485 20.1736 38.0531C20.1736 37.5578 20.3696 37.0826 20.7188 36.7313L27.3563 30L20.7188 23.3625C20.4116 23.0038 20.2511 22.5424 20.2694 22.0705C20.2876 21.5986 20.4832 21.151 20.8171 20.8171C21.1511 20.4832 21.5987 20.2875 22.0706 20.2693C22.5425 20.2511 23.0039 20.4116 23.3626 20.7188L30.0001 27.3563L36.6376 20.7188C36.8111 20.539 37.0184 20.3952 37.2476 20.2956C37.4768 20.196 37.7233 20.1424 37.9732 20.1381C38.223 20.1337 38.4713 20.1786 38.7038 20.2702C38.9363 20.3618 39.1484 20.4983 39.3282 20.6719C39.5079 20.8455 39.6517 21.0528 39.7514 21.2819C39.851 21.5111 39.9045 21.7576 39.9089 22.0075C39.9132 22.2573 39.8683 22.5056 39.7767 22.7381C39.6851 22.9706 39.5486 23.1828 39.3751 23.3625L32.6438 30L39.3751 36.6375Z" fill="white" />
					</svg>
				</div>
			</Modal>
		</>
	)
}