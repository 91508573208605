import React from 'react'
import Select from "react-select";
import { customStyles } from '../../../utils';
import mainAdvisorsData from '../../../hooks/mainAdvisorsData';
import { postRequest } from '../../../config';

const UserAllocationManagement = ({ sectionList, TablePagination, rowsPerPage, currentPage, handleChangePage, handleChangeRowsPerPage }) => {

    const { advisorsData, data, setData } = mainAdvisorsData()
    const paginatedData = sectionList?.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

    const handleAllocation = async ({ advisorId, sectionId, advisorName, sectionNo }) => {
        try {
            const response = await postRequest(`/userAllocation/section`, { advisorId, sectionId, advisorName, sectionNo })
            setData(response?.['body'])
        } catch (err) {
            console.log(err)
        }
    }

    const getSelectedValue = ({ sectionId }) => {
        const section = data?.filter((ad) => ad['sectionId'] === sectionId)
        if (section.length) {
            return advisorsData?.filter((i) => i._id === section[0]['advisorId'])[0]
        }
        return {}
    }

    // Custom function to format the option label
    const formatOptionLabel = ({ label, advisorTypeName }) => (
        <div>
            <span style={{ fontWeight: 'normal', textTransform: 'capitalize' }}>{label}</span>{' '}
            <span style={{ fontWeight: 'bold', color: '#555' }}>{advisorTypeName}</span>
        </div>
    );

    return (
        <>
            <div className="bg1 shadow p2rem dg gg2rem allocationSectionAdmin">
                <div className="df fdc aic bss bw2px bo5 br10px p2rem" >
                    <h3 className="fs1-5rem fs2rem-sm ttu tac c4" >Featured Business Cards Management</h3>
                    {/* <p>Allocation User Management</p> */}
                    <div className="mt2rem" style={{ overflowX: 'scroll', width: '1300px' }}>
                        <table className='w100'>
                            <tr>
                                <th className="tal p1rem" style={{ fontSize: '16px' }}>Section Name</th>
                                <th className="tal p1rem" style={{ fontSize: '16px' }}>Main Advisors</th>
                            </tr>
                            <tbody>
                                {paginatedData?.map((sec_obj, sec_i) => (
                                    <tr key={sec_i}>
                                        <td className="df fdc jcs ais w100" >
                                            <p className='df als jcs ases' style={{ textAlign: 'left !important', fontWeight: 400, fontSize: '11px' }}>
                                                {sec_obj?.sectionNo} <span className='fw600 ml0-5rem'>({sec_obj?.sectionName}) </span>
                                            </p>
                                        </td>
                                        <td className='p1rem'>
                                            <Select
                                                placeholder="Select Advisors"
                                                styles={customStyles}
                                                formatOptionLabel={formatOptionLabel}
                                                value={getSelectedValue({ sectionId: sec_obj._id })}
                                                className="p0-5rem lpins w100 br10px  custom-input cp bg1"
                                                options={advisorsData}
                                                onChange={(advisor) => handleAllocation({ advisorId: advisor._id, sectionId: sec_obj._id, advisorName: advisor.label, sectionNo: sec_obj?.sectionNo })}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="w100 df jce">
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={sectionList?.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div >
        </>
    )
}

export default UserAllocationManagement