import React from 'react'
import useAdHistoryData from '../../hooks/useAdHistoryData'

const LeadMatricReport = () => {

    const { data } = useAdHistoryData()
    return (
        <>
            <div className='df aifs jcsb'>
                {
                    data && Object.entries(data).map((item, idx) => {
                        const companyTypes = item[0]
                        const adsData = item[1]
                        return (
                            <>
                                <div class="sectionWrapper p0-5rem fs0-75rem pr w50">
                                    <div className="table-permission">
                                        <h2 className='tac'>{companyTypes}</h2>
                                        <table
                                            id={`table${idx}`}
                                            className="table-userPermissions table-clientPermissions table-report"
                                        >
                                            <thead className="ps t0">
                                                <tr>
                                                    <th className="index-table smft">Sections</th>
                                                    <th className="index-table taci smft" colSpan="3">Served</th>
                                                    <th className="index-table taci smft" colSpan="3">Clicked</th>
                                                    {
                                                        !companyTypes.includes('Indirect') ? <th className="index-table taci smft" colSpan="3">Ads</th> : null
                                                    }

                                                </tr>
                                                <tr>
                                                    <th className="index-table smft"></th>
                                                    <th className="subHead smft">Week</th>
                                                    <th className="subHead smft">Month</th>
                                                    <th className="subHead smft">YTD</th>
                                                    <th className="subHead smft">Week</th>
                                                    <th className="subHead smft">Month</th>
                                                    <th className="subHead smft">YTD</th>
                                                    {
                                                        !companyTypes.includes('Indirect') ? <th className="index-table smft">(%)</th> : null
                                                    }

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    adsData && Object.entries(adsData).map((history, index) => {
                                                        const sectionNo = history[0]
                                                        const historyData = history[1]
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <td className="c15" >
                                                                        <span className="fw600"> {sectionNo} </span>
                                                                        <h5>({historyData?.sectionName && historyData?.sectionName})</h5>
                                                                    </td>

                                                                    {/* <td className="c15" >
                                                                        <img
                                                                            style={{
                                                                                background: "gray",
                                                                                cursor: "not-allowed"
                                                                            }}
                                                                            className={`w2rem h2rem df jcc aic br100 mr0-5rem pr`}
                                                                            src={historyData["profile"] ? historyData["profile"] : require("../../../images/profile-photo.jpeg")}
                                                                            data-tooltip={`${historyData["firstName"] && historyData["firstName"]} ${historyData["lastName"] && historyData["lastName"]}`}
                                                                        />
                                                                    </td> */}

                                                                    {/* Served Data */}
                                                                    <td className="c15 cp">
                                                                        <div className="df jcc">
                                                                            {historyData['served']['weekCount']}
                                                                        </div>
                                                                    </td>
                                                                    <td className="c15 cp">
                                                                        <div className="df jcc">
                                                                            {historyData['served']['monthServed']}
                                                                        </div>
                                                                    </td>
                                                                    <td className="c15 cp">
                                                                        <div className="df jcc">
                                                                            {historyData['served']['ytdCount']}
                                                                        </div>
                                                                    </td>
                                                                    {/* Clicked Data */}
                                                                    <td className="c15 cp">
                                                                        <div className="df jcc">
                                                                            {historyData['clicked']['weekCount']}
                                                                        </div>
                                                                    </td>
                                                                    <td className="c15 cp">
                                                                        <div className="df jcc">
                                                                            {historyData['clicked']['monthServed']}
                                                                        </div>
                                                                    </td>
                                                                    <td className="c15 cp">
                                                                        <div className="df jcc">
                                                                            {historyData['clicked']['ytdCount']}
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        historyData['percentageOfTotalClients'] ? <td className="c15 cp">
                                                                            <div className="df jcc">
                                                                                {historyData['percentageOfTotalClients']}
                                                                            </div>
                                                                        </td> : null
                                                                    }


                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </div>

        </>
    )
}

export default LeadMatricReport