import { useCallback, useState, useEffect } from "react";
import { getRequest } from "../config";


const mainAdvisorsData = () => {

    const [data, setData] = useState([])
    const [advisorsData, setAdvisorsData] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            const response = await getRequest(`/user/getAllMainAdvisors`, {}, setAdvisorsData)
            const _data = response.body.map((advisor) => ({ ...advisor, label: `${advisor?.firstName ?? advisor?.firstName} ${advisor?.lastName ?? advisor?.lastName}`, value: `${advisor?._id}`, ...((advisor?.advisorType && advisor?.advisorType?.name) && { advisorTypeName: `(${advisor?.advisorType?.name})` }) }))
            setAdvisorsData(_data);
        } catch (error) {
            setAdvisorsData({})
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false)
        }
    }, []);

    const fetchDefaultData = useCallback(async () => {
        setLoading(true)
        try {
            const response = await getRequest(`/userAllocation/section`, {}, setData)
            setData(response.body);
        } catch (error) {
            setData({})
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false)
        }
    }, []);

    useEffect(() => {
        fetchDefaultData()
        fetchData()
    }, [])

    return { data, advisorsData, setData }
}

export default mainAdvisorsData;