import { useCallback, useState, useEffect } from "react";
import { getRequest } from "../config";

const useAdHistoryData = () => {

    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            const response = await getRequest(`/ads/history`, {}, setData)
            setData(response.body);
        } catch (error) {
            setData({})
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false)
        }
    }, []);

    useEffect(() => {
        fetchData()
    }, [])

    return { data, loading }
}

export default useAdHistoryData;